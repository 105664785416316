import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { InteractiveHeaderComponent } from './components/interactive-header/interactive-header.component';
import { PatientData } from '../patient-details/patient-details.component';
import { MedicalFinding, PatientRecordVCData, Record, Region, SubRegion } from '../../models/patient-records.model';
import { getNewViewContent, ViewContent } from '../../models/view-content.models/view-content.model';
import { LabReport, LabResult } from '../../models/view-content.models/view-content-clinic-domain.model';
import { FindingListComponent } from './components/finding-list/finding-list.component';
import { FindingViewerComponent } from './components/finding-viewer/finding-viewer.component';
import { ImageDocDrawerComponent } from './components/image-doc-drawer/image-doc-drawer.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ViewContentCacheService } from '../../services/cache/view-content-cache.service';
import { CanvasJson } from '@lohmann-birkner/luic/lib/components/luic-painting-tool/painting-tool.component';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { FullscreenDialogComponent } from '../../modals/fullscreen-dialog/fullscreen-dialog.component';
import dayjs from 'dayjs';
import {
    FormioRendererForm,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormModalComponent } from '../../modals/form-modal/form-modal.component';
import { v4 as uuidv4 } from 'uuid';
import {
    TextEditorBridgeComponent,
    TextEditorModalResult,
} from '../../components/utility/text-editor-bridge/text-editor-bridge.component';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

export interface ExtendedRegion extends Region {
    selected: boolean;
}

export interface ExtendedRecord extends Record {
    selected: boolean;
}

export interface ExtendedFinding extends MedicalFinding {
    selected: boolean;
    annotations: CanvasJson;
    locator: string;
    region: Region;
    subRegion: SubRegion;
    record: Record;
}

export interface ExtendedLabor extends LabResult {
    selected: boolean;
}

export interface FindingItem<T> {
    type: 'header' | 'item';
    text: string;
    count?: number;
    data?: T;
}

@Component({
    selector: 'app-med-view',
    templateUrl: './med-view.component.html',
    styleUrls: ['./med-view.component.scss'],
    standalone: true,
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'shadow' },
        },
    ],
    imports: [InteractiveHeaderComponent, FindingListComponent, FindingViewerComponent],
})
export class MedViewComponent implements OnInit, OnChanges {
    @Input({ required: true }) patientRecordData!: PatientData;

    public regions: ExtendedRegion[] = [];
    public records: ExtendedRecord[] = [];
    public vcRecord: ViewContent<PatientRecordVCData>[] = [];
    public vcLabor: ViewContent<LabReport> = {} as ViewContent<LabReport>;
    public findings: ExtendedFinding[] = [];
    public labor: ExtendedLabor[] = [];
    public findingsList: FindingItem<any>[] = [];
    public laborTable: FindingItem<{ allLabResults: LabResult[]; documentType: string }> = {} as FindingItem<{
        allLabResults: LabResult[];
        documentType: string;
    }>;

    private accessToken: string | undefined;
    private lastSelectedFinding: FindingItem<any> | undefined = undefined;
    private lastSelectedRegion: ExtendedRegion | undefined = undefined;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private dialog: MatDialog,
        private vcCache: ViewContentCacheService,
        private accessFacade: AccessFacadeService,
        private snackBar: MatSnackBar
    ) {
        firstValueFrom(this.accessFacade.userTokens$).then((t) => {
            this.accessToken = t.token?.access_token;
        });
        this.registerIcons();
    }

    public get allLabResults(): LabResult[] {
        return this.vcLabor?.data?.results || [];
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['patientRecordData']?.currentValue &&
            Object.keys(changes['patientRecordData']?.currentValue).length > 0
        ) {
            this.initializeData();
            this.refresh();
        }
    }

    public async onClickOnOpenPaintingTool() {
        const selectedFinding: FindingItem<ExtendedFinding> | undefined = this.getSelectedFinding();
        if (!selectedFinding) return;

        const dialogRef = this.dialog.open(ImageDocDrawerComponent, {
            data: {
                canvasElementId: 'myCanvas',
                selectedFinding: this.getSelectedFinding(),
            },
            height: '95vh',
            width: '95%',
            maxWidth: '100%',
        });

        const res: { role: string; data: any } = await firstValueFrom(dialogRef.afterClosed());

        if (res.role === 'save' && this.accessToken) {
            const vcFinding = this.vcRecord.find((f) => f.locator === selectedFinding?.data?.locator);
            if (vcFinding) {
                vcFinding.data.annotations = res.data.annotations;
                // vcFinding.data.annotations = {} as any;

                try {
                    await this.vcCache.saveViewContent(vcFinding);

                    this.snackBar.open('Dein Befund wurde aktualisiert', '', {
                        duration: 3000, // Adjust duration as needed
                        panelClass: ['success-snackbar'],
                    });
                } catch (e) {
                    console.error(e);
                    this.snackBar.open('Dein Befund konnte nicht aktualisiert werden.', '', {
                        duration: 5000, // Adjust duration as needed
                        panelClass: ['error-snackbar'],
                    });
                }
            }
        }
    }

    public onClickOnOpenFullscreen() {
        const selectedFinding = this.getSelectedFinding();
        if (!selectedFinding) return;

        const dialogData = {
            data: selectedFinding.data.documentType === 'lab-report' ? this.allLabResults : selectedFinding.data,
            documentType:
                selectedFinding.data.documentType === 'lab-report' ? 'labor' : selectedFinding.data.documentType,
            selectedFinding,
        };

        this.dialog.open(FullscreenDialogComponent, {
            data: dialogData,
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        });
    }

    public onClickOnOpenLabTableFullscreen() {
        this.dialog.open(FullscreenDialogComponent, {
            data: { data: this.allLabResults, documentType: 'lab-table' },
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        });
    }

    public selectRegion(region: ExtendedRegion) {
        this.regions.forEach((r) => {
            if (r !== region) {
                r.selected = false;
            }
        });

        region.selected = !region.selected;

        this.lastSelectedRegion = region;
        this.lastSelectedFinding = undefined;
        this.refresh();
    }

    public selectRecord(record: ExtendedRecord) {
        this.records.forEach((r) => (r.selected = false));
        record.selected = true;
        this.refresh();
    }

    public selectFinding(fl: FindingItem<any>) {
        this.findingsList.forEach((r) => {
            if (r.type === 'item' && r.data) {
                r.data.selected = false;
            }
        });

        if (fl.data) {
            fl.data.selected = true;
            this.lastSelectedFinding = fl;
        }
    }

    public getSelectedFinding(): FindingItem<any> | undefined {
        return this.findingsList.find((r) => r.type === 'item' && r.data?.selected);
    }

    public getSelectedRecord(): ExtendedRecord | undefined {
        return this.records.find((r: ExtendedRecord) => r.selected);
    }

    public openLaborViewer() {
        this.laborTable = {
            data: { allLabResults: this.allLabResults, documentType: 'lab-table' },
            text: 'Labortabelle',
            type: 'item',
        };
    }

    public sortViewContentsByDate(): void {
        this.vcRecord = this.vcRecord.sort((a, b) => {
            const dateA = new Date(a.data.finding.updated_at || '').getTime();
            const dateB = new Date(b.data.finding.updated_at || '').getTime();
            return dateB - dateA;
        });

        if (this.vcLabor?.data?.results) {
            this.vcLabor.data.results = this.vcLabor.data.results.sort((a, b) => {
                const dateA = new Date(a.modified_at || '').getTime();
                const dateB = new Date(b.modified_at || '').getTime();
                return dateB - dateA;
            });
        }
    }

    public async saveScreenshot(finding: ExtendedFinding) {
        const currentRecord: ExtendedRecord | undefined = this.records.find((r: ExtendedRecord) => {
            return r.selected;
        });

        const id = uuidv4();

        const data: PatientRecordVCData = {
            area: finding.region,
            finding: {
                base64: finding.base64?.split(',')[1],
                case_id: parseInt(this.patientRecordData.cid),
                clinical_info: finding.clinical_info,
                createdBy: finding.createdBy,
                created_at: finding.created_at,
                dateTimeOfRecord: finding.dateTimeOfRecord,
                description: finding.description,
                documentType: 'image',
                external_documentid: finding.external_documentid,
                filename: `${finding.filename} (Einzelbild)`,
                id: id,
                patient_id: 0,
                patient_number: '',
                type_id: 0,
                updated_at: '',
                updated_by: '',
                visit_number: '',
            },
            record: currentRecord as Record,
            subarea: {
                text_long: 'Sonstiges',
                sort_order: 0,
                id: -1,
            },
        };

        let vcData: ViewContent<any> | undefined;
        vcData = getNewViewContent({
            locator: `document.others.${this.patientRecordData.cid}.${id}`,
            related_patient_id: this.patientRecordData.pid,
            related_case_id: this.patientRecordData.cid,
            data,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'final',
            form: {} as FormioRendererForm,
        });

        try {
            await this.vcCache.saveViewContent(vcData);

            this.snackBar.open('Dein Screenshot wurde erstellt', '', {
                duration: 3000, // Adjust duration as needed
                panelClass: ['success-snackbar'],
            });
        } catch (e) {
            console.error(e);
            this.snackBar.open('Dein Screenshot konnte nicht erstellt werden.', '', {
                duration: 5000, // Adjust duration as needed
                panelClass: ['error-snackbar'],
            });
        }
    }

    public async onClickOpenNurseFormular() {
        if (this.accessToken) {
            const form_vc: ViewContent<any> | undefined | null = await this.vcCache.getViewContentForLocator(
                'form.static.nursing_report_form.1'
            );

            if (!form_vc) {
                this.snackBar.open('Es muss zuerst ein Pflegebericht Formular erstellt und abgespeichtert werden', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
                return;
            }

            form_vc.data.formio_form.components.unshift({
                key: 'title',
                type: 'textfield',
                input: true,
                label: 'Titel des Pflegeberichts',
                tableView: true,
                labelWidth: 1,
                applyMaskOn: 'change',
                labelMargin: 1,
                labelPosition: 'left-left',
                validateWhenHidden: false,
                'x-data': '',
                'x-show': true,
            });

            const dialogRef = this.dialog.open(FormModalComponent, {
                data: {
                    formioForm: form_vc.data.formio_form,
                    viewContentI18n: form_vc.data.formio_i18n,
                    form_title: 'Neuer Pflegebericht',
                },
                panelClass: 'patient-overview-dialog-container',
            });

            const res = await firstValueFrom(dialogRef.afterClosed());

            if (res?.role === 'cancel') return;

            const cid = this.patientRecordData.cid;
            const pid = this.patientRecordData.pid;

            if (!cid || !pid) {
                console.error('Error creating Nurseformular');
                return;
            }

            const i18n: FormioRendererI18n = {
                de: {
                    Eingabe: 'Eingabe',
                    Speichern: 'Speichern',
                    Abbrechen: 'Abbrechen',
                    'Field Set': 'Field Set',
                },
                en: {
                    Eingabe: 'Input',
                    Speichern: 'Save',
                    Abbrechen: 'Cancel',
                    'Field Set': 'Field Set',
                },
            };

            let filename = form_vc.data.formio_form.components[0]?.['x-data'];
            form_vc.data.formio_form.components.shift();
            res.data.shift();

            if (!filename) {
                filename = 'Pflegebericht';
            }

            const currentRecord: ExtendedRecord | undefined = this.records.find((r: ExtendedRecord) => {
                return r.selected;
            });

            const currentRegion: ExtendedRegion | undefined = this.regions.find((r: ExtendedRegion) => {
                return r.code === 'default_document';
            });

            const id = uuidv4();

            const data: PatientRecordVCData = {
                area: currentRegion as Region,
                finding: {
                    case_id: parseInt(this.patientRecordData.cid),
                    documentType: 'form',
                    filename: filename,
                    formioRendererData: res.data,
                    formioRendererForm: form_vc.data.formio_form,
                    id: id,
                },
                record: currentRecord as Record,
                subarea: {
                    text_long: 'Sonstiges',
                    sort_order: 0,
                    id: -1,
                },
            };

            let vcData: ViewContent<any>;
            vcData = getNewViewContent({
                locator: `document.others.${this.patientRecordData.cid}.${id}`,
                related_patient_id: this.patientRecordData.pid,
                related_case_id: this.patientRecordData.cid,
                data,
                owners: [],
                owner_departments: [],
                main_owner_job_type: 'other',
                created_at: dayjs().toISOString(),
                status: 'final',
                form: form_vc.data.formio_form,
            });

            try {
                await this.vcCache.saveViewContent(vcData);

                this.snackBar.open('Dein Pflegebericht wurde erstellt', '', {
                    duration: 3000, // Adjust duration as needed
                    panelClass: ['success-snackbar'],
                });
            } catch (e) {
                console.error(e);
                this.snackBar.open('Dein Pflegebericht konnte nicht erstellt werden.', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
            }
        }
    }

    public async onClickOpenDoctorFormular() {
        if (this.accessToken) {
            const form_vc: ViewContent<any> | undefined | null = await this.vcCache.getViewContentForLocator(
                'form.static.doctors_report_form.1'
            );

            if (!form_vc) {
                this.snackBar.open('Es muss zuerst ein Arztbericht Formular erstellt und abgespeichtert werden', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
                return;
            }

            form_vc.data.formio_form.components.unshift({
                key: 'title',
                type: 'textfield',
                input: true,
                label: 'Titel des Arztberichts',
                tableView: true,
                labelWidth: 1,
                applyMaskOn: 'change',
                labelMargin: 1,
                labelPosition: 'left-left',
                validateWhenHidden: false,
                'x-data': '',
                'x-show': true,
            });

            const dialogRef = this.dialog.open(FormModalComponent, {
                data: {
                    formioForm: form_vc.data.formio_form,
                    viewContentI18n: form_vc.data.formio_i18n,
                    form_title: 'Neuer Arztbericht',
                },
                panelClass: 'patient-overview-dialog-container',
            });

            const res = await firstValueFrom(dialogRef.afterClosed());

            if (res?.role === 'cancel') return;

            const cid = this.patientRecordData.cid;
            const pid = this.patientRecordData.pid;

            if (!cid || !pid) {
                console.error('Error creating Doctorformular');
                return;
            }

            const i18n: FormioRendererI18n = {
                de: {
                    Eingabe: 'Eingabe',
                    Speichern: 'Speichern',
                    Abbrechen: 'Abbrechen',
                    'Field Set': 'Field Set',
                },
                en: {
                    Eingabe: 'Input',
                    Speichern: 'Save',
                    Abbrechen: 'Cancel',
                    'Field Set': 'Field Set',
                },
            };

            let filename = form_vc.data.formio_form.components[0]?.['x-data'];
            form_vc.data.formio_form.components.shift();
            res.data.shift();

            if (!filename) {
                filename = 'Arztbericht';
            }

            const currentRecord: ExtendedRecord | undefined = this.records.find((r: ExtendedRecord) => {
                return r.selected;
            });

            const currentRegion: ExtendedRegion | undefined = this.regions.find((r: ExtendedRegion) => {
                return r.code === 'default_document';
            });

            const id = uuidv4();

            const data: PatientRecordVCData = {
                area: currentRegion as Region,
                finding: {
                    case_id: parseInt(this.patientRecordData.cid),
                    documentType: 'form',
                    filename: filename,
                    formioRendererData: res.data,
                    formioRendererForm: form_vc.data.formio_form,
                    id: id,
                    edit_status: res.data.find((item: any) => item.key === 'status')?.value ?? 'completed',
                    edit_until: dayjs().add(48, 'hour').toISOString(),
                },
                record: currentRecord as Record,
                subarea: {
                    text_long: 'Sonstiges',
                    sort_order: 0,
                    id: -1,
                },
            };

            //document.others.2340.39ae43bf-ae57-40ed-8817-3b9cc62cca82
            let vcData: ViewContent<any>;
            vcData = getNewViewContent({
                locator: `document.others.${this.patientRecordData.cid}.${id}`,
                related_patient_id: this.patientRecordData.pid,
                related_case_id: this.patientRecordData.cid,
                data,
                owners: [],
                owner_departments: [],
                main_owner_job_type: 'other',
                created_at: dayjs().toISOString(),
                status: 'final',
                form: form_vc.data.formio_form,
            });

            try {
                await this.vcCache.saveViewContent(vcData);

                this.snackBar.open('Dein Arztbericht wurde erstellt', '', {
                    duration: 3000, // Adjust duration as needed
                    panelClass: ['success-snackbar'],
                });
            } catch (e) {
                console.error(e);
                this.snackBar.open('Dein Arztbericht konnte nicht erstellt werden.', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
            }
        }
    }

    public async saveForm(finding: FindingItem<ExtendedFinding> | undefined) {
        const cid = this.patientRecordData.cid;
        const pid = this.patientRecordData.pid;

        if (!cid || !pid) {
            console.error('Error creating Doctorformular');
            return;
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        const currentRecord: ExtendedRecord | undefined = this.records.find((r: ExtendedRecord) => {
            return r.selected;
        });

        let filename = finding?.data?.filename ?? 'Aktenformular';

        const id = finding?.data?.id;

        const data: PatientRecordVCData = {
            finding: {
                case_id: parseInt(this.patientRecordData.cid),
                documentType: 'form',
                filename: filename,
                formioRendererData: finding?.data?.formioRendererData,
                formioRendererForm: finding?.data?.formioRendererForm,
                id: id as string,
                edit_status: (finding?.data?.formioRendererData?.find((item: any) => item.key === 'status')?.value ??
                    finding?.data?.edit_status) as string | undefined,

                edit_until: finding?.data?.edit_until,
            },
            record: currentRecord as Record,
            area: finding?.data?.region ?? ({} as Region),
            subarea: finding?.data?.subRegion ?? ({} as SubRegion),
        };

        let vcData: ViewContent<any> = {} as ViewContent<any>;
        vcData = getNewViewContent({
            locator: finding?.data?.locator ?? '',
            related_patient_id: pid,
            related_case_id: cid,
            data,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            status: 'final',
            form: finding?.data?.formioRendererForm,
            i18n,
        });

        try {
            await this.vcCache.saveViewContent(vcData);

            this.snackBar.open('Dein Formular wurde gespeichert', '', {
                duration: 3000, // Adjust duration as needed
                panelClass: ['success-snackbar'],
            });
        } catch (e) {
            console.error(e);
            this.snackBar.open('Dein Formular konnte nicht gespeichert werden.', '', {
                duration: 5000, // Adjust duration as needed
                panelClass: ['error-snackbar'],
            });
        }
    }

    public async onClickOpenTexteditor(): Promise<void> {
        // Opens a dialog for adding a new patient file and prevents it from auto-refocusing
        const dialogRef = this.dialog.open<TextEditorBridgeComponent, any, TextEditorModalResult>(
            TextEditorBridgeComponent,
            {
                restoreFocus: false,
                height: '90%',
                width: '90%',
                data: {
                    caseId: this.patientRecordData.cid,
                },
            }
        );

        const res = await firstValueFrom(dialogRef.afterClosed());

        if (res?.role === 'cancel') return;

        const $event: TextEditorModalResult['data'] | undefined = res?.data;
        if (!$event) return;

        const cid = this.patientRecordData.cid;
        const pid = this.patientRecordData.pid;

        if (!cid || !pid) {
            console.error('Error creating Doctorformular');
            return;
        }

        if (!this.accessToken) throw Error('No token available to POST document');
        if (!cid) throw Error('No case Id available to store document');

        const currentRecord: ExtendedRecord | undefined = this.records.find((r: ExtendedRecord) => {
            return r.selected;
        });

        const currentRegion: ExtendedRegion | undefined = this.regions.find((r: ExtendedRegion) => {
            return r.code === 'default_document';
        });

        const id = uuidv4();

        const data: PatientRecordVCData = {
            area: $event.area ?? (currentRegion as Region),
            finding: {
                base64: $event.content,
                case_id: parseInt(this.patientRecordData.cid),
                documentType: 'pdf',
                filename: $event.title ?? '',
                id: id,
            },
            record: currentRecord as Record,
            subarea: $event.subarea ?? {
                text_long: 'Sonstiges',
                sort_order: 0,
                id: -1,
            },
        };

        //document.others.2340.cefdb91c-3e63-42ac-a77d-3f576a330679
        let vcData: ViewContent<any>;
        vcData = getNewViewContent({
            locator: `document.others.${this.patientRecordData.cid}.${id}`,
            related_patient_id: pid,
            related_case_id: cid,
            data,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'final',
        });

        try {
            await this.vcCache.saveViewContent(vcData);

            this.snackBar.open('Dein Dokument wurde erstellt', '', {
                duration: 3000, // Adjust duration as needed
                panelClass: ['success-snackbar'],
            });
        } catch (e) {
            console.error(e);
            this.snackBar.open('Dein Dokument konnte nicht erstellt werden.', '', {
                duration: 5000, // Adjust duration as needed
                panelClass: ['error-snackbar'],
            });
        }
    }

    private detectDocumentType(base64String: string): string {
        const cleanBase64 = base64String.trim().toLowerCase();
        if (cleanBase64.startsWith('jvberi0')) return 'pdf';
        if (
            cleanBase64.startsWith('/9j/') ||
            cleanBase64.startsWith('ivborw0kggo') ||
            cleanBase64.startsWith('r0lgodlh')
        )
            return 'image';
        if (cleanBase64.startsWith('aaaa') && cleanBase64.includes('redtq==')) return 'dicom';
        if (
            cleanBase64.startsWith('uf8=') ||
            cleanBase64.startsWith('77u/') ||
            cleanBase64.startsWith('0m8r4k') ||
            cleanBase64.startsWith('XEVc')
        )
            return 'text';
        if (cleanBase64.startsWith('pk') || cleanBase64.startsWith('ums=')) return 'archive';
        if (cleanBase64.startsWith('suqzbaaaaaa')) return 'audio';
        if (cleanBase64.startsWith('id3')) return 'audio';
        if (cleanBase64.startsWith('riffffffff')) return 'video';
        if (cleanBase64.startsWith('0000002066747970') || cleanBase64.startsWith('aaaahg==')) return 'video';
        if (cleanBase64.startsWith('dw9yza==') || cleanBase64.startsWith('0m8r4k')) return 'document';
        if (cleanBase64.startsWith('ums+') || cleanBase64.startsWith('0m8r4k')) return 'spreadsheet';
        if (cleanBase64.startsWith('dgi6')) return 'presentation';

        try {
            if (/^[\x00-\x7F]*$/.test(atob(base64String))) return 'base64_text';
        } catch {}
        return 'base64_text';
    }

    private registerIcons() {
        const icons = [
            { name: 'clinical_notes', path: 'assets/icons/clinical_notes.svg' },
            { name: 'prescriptions', path: 'assets/icons/prescriptions.svg' },
        ];

        icons.forEach((icon) => {
            this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
        });
    }

    private initializeData() {
        this.initializeRegions();
        this.initializeRecords();
    }

    private initializeRegions() {
        if (this.patientRecordData.regions?.length > 0) {
            const findingsWithoutRegion = this.patientRecordData.finding.filter(
                (f: ViewContent<PatientRecordVCData>) => !f.data.area
            );

            let sonstigesRegion: ExtendedRegion | undefined = this.patientRecordData.regions.find(
                (r: Region) => r.long_text === 'Sonstiges'
            ) as ExtendedRegion;

            if (findingsWithoutRegion.length > 0) {
                if (!sonstigesRegion) {
                    sonstigesRegion = { id: 0, selected: false, long_text: 'Sonstiges' };
                }

                findingsWithoutRegion.forEach(
                    (f: ViewContent<PatientRecordVCData>) => (f.data.area = sonstigesRegion as Region)
                );
            }

            const defaultRegion: ExtendedRegion = { id: -1, long_text: 'Alle', selected: !this.lastSelectedRegion };
            this.regions = [
                defaultRegion,
                ...this.patientRecordData.regions.map((region) => ({
                    ...region,
                    selected: this.lastSelectedRegion?.id === region.id,
                })),
            ];

            if (sonstigesRegion && !this.regions.some((r) => r.id === sonstigesRegion!.id)) {
                this.regions.push(sonstigesRegion);
            }

            if (this.lastSelectedRegion && !this.regions.some((r) => r.selected)) {
                const fallbackRegion = this.regions.find((r) => r.id === this.lastSelectedRegion?.id);
                if (fallbackRegion) fallbackRegion.selected = true;
            }
        }
    }

    private initializeRecords() {
        if (this.patientRecordData.record?.length > 0) {
            const findingsWithoutRecord = this.patientRecordData.finding.filter(
                (f: ViewContent<PatientRecordVCData>) => !f.data.record
            );

            let sonstigesRecord: ExtendedRecord | undefined = this.patientRecordData.record.find(
                (r: Record) => r?.name === 'Sonstiges'
            ) as ExtendedRecord;

            if (findingsWithoutRecord.length > 0) {
                if (!sonstigesRecord) {
                    sonstigesRecord = {
                        clinicId: '',
                        id: '0',
                        name: 'Sonstiges',
                        selected: false,
                        validFrom: '',
                        validUntil: '',
                        version: '',
                    };
                }

                findingsWithoutRecord.forEach(
                    (f: ViewContent<PatientRecordVCData>) => (f.data.record = sonstigesRecord as Record)
                );
            }

            this.records = this.patientRecordData.record.map((record) => ({
                ...record,
                selected: false,
            }));

            if (sonstigesRecord && !this.records.some((r) => r.id === sonstigesRecord!.id)) {
                this.records.push(sonstigesRecord);
            }

            if (!this.records.some((r) => r.selected)) {
                this.records[0].selected = true;
            }
        }
    }

    private refresh() {
        this.initializeVcData();
        this.sortViewContentsByDate();
        this.applyFilters();
        this.findingsList = this.createFindingsList();
    }

    private initializeVcData() {
        this.vcRecord =
            this.patientRecordData.finding?.map((f) => ({
                ...f,
                selected: false,
                subRegion: f.data.subarea,
            })) || [];

        this.vcLabor = {
            ...this.patientRecordData.labor,
            data: {
                ...this.patientRecordData.labor?.data,
                results:
                    this.patientRecordData.labor?.data?.results.map((result) => ({
                        ...result,
                        selected: false,
                    })) || [],
            },
        };
    }

    private applyFilters() {
        const currentRegion = this.regions.find((r) => r.selected) || ({ id: -1 } as ExtendedRegion);
        this.findings = this.vcRecord
            .filter((vc) => this.isValidFinding(vc, currentRegion))
            .map((vc) => this.transformFinding(vc));

        this.labor =
            this.vcLabor?.data?.results
                .filter((vc) => this.isValidLabor(vc, currentRegion))
                .map((vc) => ({ ...vc, selected: false })) || [];
    }

    private transformFinding(vc: ViewContent<PatientRecordVCData>): ExtendedFinding {
        return {
            ...vc.data.finding,
            annotations: vc.data.annotations || ({} as CanvasJson),
            documentType: vc.data.finding.documentType ?? this.detectDocumentType(vc.data.finding.base64 ?? ''),
            locator: vc.locator,
            record: vc.data.record,
            region: vc.data.area,
            selected: false,
            subRegion: vc.data.subarea,
        };
    }

    private isValidFinding(vc: ViewContent<PatientRecordVCData>, currentRegion: ExtendedRegion): boolean {
        return (
            (currentRegion?.id === -1 || vc.data.area?.id === currentRegion?.id) &&
            this.records.some((record) => record?.name === vc.data.record?.name)
        );
    }

    private isValidLabor(vc: LabResult, currentRegion: ExtendedRegion): boolean {
        return (
            (currentRegion?.id === -1 || vc.area?.id === currentRegion?.id) &&
            this.records.some((record) => record?.name === vc.record?.name)
        );
    }

    private createFindingsList(): FindingItem<any>[] {
        const flatList: FindingItem<any>[] = [];
        let miscHeader: FindingItem<any> | null = null;
        const miscItems: FindingItem<any>[] = [];

        const addGroupedItems = <T extends { subRegion?: SubRegion }>(
            items: T[],
            headerTextExtractor: (item: T) => string,
            textExtractor: (item: T) => string
        ) => {
            // SubRegions mergen und gruppieren
            const grouped = items.reduce(
                (acc, item) => {
                    const subRegionName = item.subRegion?.text_long || item.subRegion?.long_text || 'Sonstiges';

                    // Wenn die SubRegion bereits existiert, merge die Items
                    if (!acc[subRegionName]) {
                        acc[subRegionName] = { subRegion: item.subRegion, items: [] };
                    }

                    acc[subRegionName].items.push(item);
                    return acc;
                },
                {} as { [key: string]: { subRegion?: SubRegion; items: T[] } }
            );

            Object.keys(grouped).forEach((subRegionName) => {
                const group = grouped[subRegionName];
                const itemsInGroup = group.items;

                // Prüfen, ob ein Header mit dem gleichen Text bereits existiert und Items mergen
                const existingHeader = flatList.find(
                    (entry) => entry.type === 'header' && entry.text === subRegionName
                );

                const newHeader: FindingItem<any> = { type: 'header', text: subRegionName, count: itemsInGroup.length };
                const newItems = itemsInGroup.map((item) => ({
                    type: 'item' as const,
                    text: textExtractor(item),
                    data: item,
                }));

                if (subRegionName === 'Sonstiges') {
                    if (!miscHeader) {
                        miscHeader = newHeader;
                    } else {
                        if (miscHeader.count) miscHeader.count += itemsInGroup.length;
                    }
                    miscItems.push(...newItems);
                } else if (existingHeader && existingHeader.type === 'header') {
                    const startIndex = flatList.indexOf(existingHeader) + 1;
                    const nextHeaderIndex = flatList.findIndex(
                        (entry, index) => index > startIndex && entry.type === 'header'
                    );

                    const insertIndex = nextHeaderIndex === -1 ? flatList.length : nextHeaderIndex;

                    flatList.splice(insertIndex, 0, ...newItems);

                    if (existingHeader.count) existingHeader.count += itemsInGroup.length;
                } else {
                    flatList.push(newHeader, ...newItems);
                }
            });
        };

        addGroupedItems<ExtendedFinding>(
            this.findings,
            (item) => item.subRegion?.text_long || 'Sonstiges',
            (item) => item.filename
        );

        addGroupedItems<ExtendedLabor>(
            this.labor,
            (item) => item.subRegion?.text_long || 'Sonstiges',
            (item) => `Laborbefund ${item.id}`
        );

        if (miscHeader) {
            flatList.push(miscHeader, ...miscItems);
        }

        if (!this.lastSelectedFinding) {
            for (const entry of flatList) {
                if (entry.type === 'item') {
                    entry.data.selected = true;
                    this.lastSelectedFinding = entry;
                    break;
                }
            }
        } else {
            for (const entry of flatList) {
                if (entry.data?.id === this.lastSelectedFinding?.data?.id) {
                    entry.data.selected = true;
                    break;
                }
            }
        }
        return flatList;
    }
}
