<div class="task-list-wrap">
    <mat-dialog-content>
        <mat-card-title class="task-list-patient-info" cdkFocusRegionstart>
            <div>
                <div class="h5">
                    {{ 'COMPONENT.PAGE_ORGA.tasks' | translate }}
                </div>
                <div class="task-patient-info-text p">
                    <strong> {{ data.patient_info.lastName }}, {{ data.patient_info.firstName }}</strong
                    >, {{ 'GENERAL.' + data.patient_info.gender | translate }},
                    {{ data.patient_info.dob | date: ('GENERAL.date_format' | translate) }}
                    ({{ data.patient_info.dob | calAge }}), {{ data.patient_info.ward }},
                    {{ 'COMPONENT.PAGE_PATIENT_LIST.room' | translate }}{{ data.patient_info.room.split(' ')[1] }},
                    {{ data.patient_info.bed.split(' ')[1] }}
                </div>
            </div>
            <div class="task-patient-info-buttons">
                <button mat-mini-fab (click)="showHistory = !showHistory">
                    <mat-icon>track_changes</mat-icon>
                </button>
                <button mat-mini-fab (click)="onClose()">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </mat-card-title>

        <mat-card-content>
            <mat-list *ngFor="let task of history" class="task-list">
                <mat-list-item>
                    <div class="task-list-button">
                        <div>
                            <div class="table-text task-list-details">
                                <span>
                                    {{ 'COMPONENT.PAGE_PATIENT_LIST.editor' | translate }}: {{ task.data.editor.name }}
                                    {{ task.data.editor.surname }}
                                </span>
                                <span>
                                    {{ 'COMPONENT.PAGE_ORGA.goalDateOn' | translate }}:
                                    {{ task.data.goalDateOn | date: ('GENERAL.date_format' | translate) }}
                                </span>
                                <span>
                                    {{ 'COMPONENT.PAGE_ORGA.priority' | translate }}:
                                    {{ task.data.priority }}
                                </span>
                            </div>

                            <div class="task-list-task-name table-text whitespace-normal">
                                {{ 'COMPONENT.PAGE_ORGA.task_name' | translate }}: {{ task.data.task_name }};
                            </div>
                            <div *ngIf="task.data.details" class="task-list-task-details table-text">
                                {{ 'COMPONENT.PAGE_ORGA.details' | translate }}:
                                {{ task.data.details }}
                            </div>
                        </div>

                        <mat-icon
                            class="hover:cursor-pointer"
                            (click)="onClickOnElement('form_tasks.json ', task.data, data.patient_info, history)"
                            matListItemIcon
                            >edit</mat-icon
                        >
                    </div>
                </mat-list-item>
                <div *ngIf="showHistory" [matBadge]="task.history?.length" matBadgeOverlap="false" class="w-fit h6">
                    {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.changing_history' | translate }}
                </div>
                <ng-container *ngFor="let oneHistory of task.history; let i = index; let last = last">
                    <app-changing-history
                        [index]="i"
                        *ngIf="showHistory"
                        [last]="last"
                        [i18n]="viewContentI18n"
                        [data]="task.history.slice(i, i + 2)"
                    ></app-changing-history>
                </ng-container>
            </mat-list>
        </mat-card-content>
    </mat-dialog-content>
</div>
