<!--
    ⚠️ The main container (patient-overview-container) is a `display: grid` which children do not have strict positioning.
    So children will be placed in rows (of three elements) until the rows get filled, then will automatically jump to the next row.
-->
<div class="patient-overview-container">
    <!-- #region Medical History (Anamnese) -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.anamnesis.title' | translate }}
            <button
                (click)="addNewVc('form_anamnesis.json', 'anamnesis', vcPatientDetails)"
                *ngIf="!caseOverview?.medical_history?.data; else editAnamnesis"
                mat-icon-button
            >
                <mat-icon>add</mat-icon>
            </button>
            <ng-template #editAnamnesis>
                <button
                    (click)="
                        updateVc(
                            caseOverview?.medical_history?.data,
                            'form_anamnesis.json',
                            'anamnesis',
                            caseOverview?.medical_history,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.medical_history?.history
                        )
                    "
                    mat-icon-button
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-template>
        </div>
        <app-dynamic-list [clipToWhenSmall]="1" [list]="[caseOverview?.medical_history]" [showButton]="false">
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_anamnesis.json',
                            'anamnesis',
                            caseOverview?.medical_history,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.medical_history?.history
                        )
                    "
                    *ngIf="item?.data; else noContent"
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data?.medical_history || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data?.date | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </div>
                </div>
            </ng-template>
            <ng-template #noContent>
                <div class="patient-details-container-content">
                    <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
                </div>
            </ng-template>
        </app-dynamic-list>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Diagnostic -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnostics.title' | translate }}
            <button (click)="addNewVc('form_diagnostics.json', 'diagnostic', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <app-dynamic-list
            *ngIf="caseOverview?.diagnostic?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.diagnostic ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_diagnostics.json',
                            'diagnostic',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.title || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.goalDateOn | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Tasks -->
    <mat-list class="third-column-task">
        <div class="patient-overview-container-title" mat-subheader>
            {{
                'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.tasks.title'
                    | translate: { count: caseOverview?.task?.length }
            }}
            <button (click)="addNewVc('form_tasks.json', 'task', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <app-dynamic-list
            *ngIf="caseOverview?.task?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.task ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_tasks.json',
                            'task',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="flex row gap-2 items-center">
                        <mat-icon
                            *ngIf="item?.data.priority === 'high' || item?.data.priority === 'veryHigh'"
                            class="task-open-icon"
                            matListItemIcon
                        >
                            priority_high
                        </mat-icon>

                        <div class="patient-overview-item-title">
                            {{ item?.data.task_name || '-' }}
                        </div>
                    </div>
                    <span
                        [ngClass]="{
                            'color-danger': item?.data.priority === 'veryHigh',
                        }"
                        matListItemLine
                    >
                        {{ item?.data.contractor?.surname || '-' }}, {{ item?.data.contractor?.name || '-' }},
                        {{ item?.data.goalDateOn | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </span>
                    <div class="patient-overview-item-line">
                        <!-- Additional content can go here -->
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Diagnosis -->
    <mat-list class="patient-overview-diagnoses">
        <div class="patient-overview-container-title" mat-subheader>
            {{
                "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnoses.title"
                    | translate
            }}
            <ng-container
                *ngIf="(caseOverview?.diagnose?.history?.length); else editDiagnosis"
            >
                <button
                    (click)="
                        addNewVc(
                            'form_diagnoses.json',
                            'diagnoses',
                            vcPatientDetails
                        )
                    "
                    mat-icon-button
                >
                    <mat-icon>add</mat-icon>
                </button>
            </ng-container>
            <ng-template #editDiagnosis>
                <button
                    (click)="
                        updateVc(
                            caseOverview?.diagnose?.data,
                            'form_diagnoses.json',
                            'diagnoses',
                            caseOverview?.diagnose,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.diagnose?.history
                        )
                    "
                    mat-icon-button
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-template>
        </div>
        <app-dynamic-list [clipToWhenSmall]="1" [list]="[caseOverview?.diagnose]" [showButton]="false">
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_diagnoses.json',
                            'diagnoses',
                            caseOverview?.diagnose,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.diagnose?.history
                        )
                    "
                    *ngIf="item?.data; else noContent"
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data?.diagnose || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.created_at | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </div>
                </div>
                <ng-template #noContent>
                    <div class="patient-details-container-content">
                        <p>
                            {{ ('GENERAL.no_content_label' | translate) || '-' }}
                        </p>
                    </div>
                </ng-template>
            </ng-template>
        </app-dynamic-list>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Consultations (Konsile) -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{
                'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.consultations.title'
                    | translate: { count: caseOverview?.consultation?.length }
            }}
            <button (click)="addNewVc('form_consultations.json', 'consultations', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <app-dynamic-list
            *ngIf="caseOverview?.consultation?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.consultation ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_consultations.json',
                            'consultations',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.title || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.goalDateOn | date: ('GENERAL.date_format' | translate) || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.details || '-' }}
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Discharge -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.discharge.title' | translate }}
            <button (click)="addNewVc('form_discharge.json', 'discharge', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <app-dynamic-list
            *ngIf="caseOverview?.discharge?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.discharge ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_discharge.json',
                            'discharge',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.comment || '-' }}
                    </div>

                    <div class="patient-overview-item-line">
                        {{ item?.data.dismissalExpectedOn | date: ('GENERAL.date_format' | translate) || '-' }}
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Therapy -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.therapy.title' | translate }}
            <button (click)="addNewVc('form_therapy.json', 'therapy', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <app-dynamic-list
            *ngIf="caseOverview?.therapy?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.therapy ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_therapy.json',
                            'therapy',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.title || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.goalDateOn | date: ('GENERAL.date_time_format' | translate) || '-' }}
                        <span>{{ item?.data.conducted ? '(D)' : item?.data.registered ? '(A)' : '-' }}</span>
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>

        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ 'GENERAL.no_content_label' | translate }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Pathogen -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.title' | translate }}
            <button (click)="addNewVc('form_pathogen.json', 'pathogen', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <app-dynamic-list
            *ngIf="caseOverview?.pathogen?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.pathogen ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_pathogen.json',
                            'pathogen',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.name || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{
                            'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.label_type'
                                | translate: { type: item?.data.type || '-' }
                        }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.date | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Post Treatment -->
    <mat-list>
        <div class="patient-overview-container-title" mat-subheader>
            {{
                'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.postTreatment.title'
                    | translate: { count: caseOverview?.post_treatment?.length }
            }}
            <button (click)="addNewVc('form_postTreatment.json', 'postTreatment', vcPatientDetails)" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <app-dynamic-list
            *ngIf="caseOverview?.post_treatment?.length; else noContent"
            [clipToWhenSmall]="1"
            [list]="caseOverview?.post_treatment ?? []"
        >
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_postTreatment.json',
                            'postTreatment',
                            item,
                            vcPatientDetails,
                            item?.i18n,
                            item?.history
                        )
                    "
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data?.postTreatment || '-' }}
                    </div>
                </div>
            </ng-template>
        </app-dynamic-list>
        <ng-template #noContent>
            <div class="patient-details-container-content">
                <p>{{ ('GENERAL.no_content_label' | translate) || '-' }}</p>
            </div>
        </ng-template>
    </mat-list>
    <!-- #endregion -->

    <!-- #region Visite -->
    <mat-list *ngIf="caseOverview?.visit_record?.data">
        <div class="patient-overview-container-title" mat-subheader>
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.visit.title' | translate }}
            <ng-container *ngIf="caseOverview?.visit_record?.history?.length === 0; else editVisit">
                <button (click)="addNewVc('form_visit.json', 'visit', vcPatientDetails)" mat-icon-button>
                    <mat-icon>add</mat-icon>
                </button>
            </ng-container>
            <ng-template #editVisit>
                <button
                    (click)="
                        updateVc(
                            caseOverview?.visit_record?.data,
                            'form_visit.json',
                            'visit',
                            caseOverview?.visit_record,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.visit_record?.history
                        )
                    "
                    mat-icon-button
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-template>
        </div>
        <app-dynamic-list [clipToWhenSmall]="2" [list]="[caseOverview?.visit_record]" [showButton]="true">
            <ng-template #itemTemplate let-item="item">
                <div
                    (click)="
                        updateVc(
                            item?.data,
                            'form_visit.json',
                            'visit',
                            caseOverview?.visit_record,
                            vcPatientDetails,
                            patientDetailsI18n,
                            caseOverview?.visit_record?.history
                        )
                    "
                    *ngIf="item?.data; else noContent"
                    class="patient-details-container-content"
                >
                    <div class="patient-overview-item-title">
                        {{ item?.data.note || '-' }}
                    </div>
                    <div class="patient-overview-item-line">
                        {{ item?.data.date_and_time | date: ('GENERAL.date_time_format' | translate) || '-' }}
                    </div>
                </div>
                <ng-template #noContent>
                    <div class="patient-details-container-content">
                        <p>
                            {{ ('GENERAL.no_content_label' | translate) || '-' }}
                        </p>
                    </div>
                </ng-template>
            </ng-template>
        </app-dynamic-list>
    </mat-list>
    <!-- #endregion -->
</div>
