<p *ngIf="vcPatientDetails && !hideCurrentPlace" class="text-gray-600 font-extralight">
    <app-breadcrumbs
        (clickOnBreadcrumb)="onClickOnBreadcrumb($event)"
        [place]="vcPatientDetails.current_place"
    ></app-breadcrumbs>
</p>

<div *ngIf="vcPatientDetails" class="flex flex-col">
    <div class="flex flex-col">
        <p>
            <span class="font-bold">{{ vcPatientDetails?.patient?.data?.surname ?? '' }}, {{ vcPatientDetails?.patient?.data?.name ?? '' }}, </span>
            <span>{{ 'GENERAL.' + (vcPatientDetails?.patient?.data?.gender ?? '') | translate }}, </span>
            <span>{{ vcPatientDetails?.patient?.data?.birth_date | date: ('GENERAL.date_format' | translate) }} </span>
            <span>({{ vcPatientDetails?.patient?.data?.birth_date | calAge }}), </span>
            <span>A:{{ vcPatientDetails?.case?.data?.admission_date | date: ('GENERAL.date_format' | translate) }},</span>
            <span> {{ 'COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date' | translate }}: </span>
            <span>{{ vcPatientDetails?.case?.data?.discharge_date | date: ('GENERAL.date_format' | translate) }} </span>
            <span>({{ vcPatientDetails?.case?.data?.discharge_date | dayAgo }})</span>
        </p>
        <p class="font-extralight diagnosis-title">
            {{ vcPatientDetails?.last_diagnosis?.data?.diagnose ?? '' }}
        </p>
    </div>
</div>
