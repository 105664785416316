import { Component, Input } from '@angular/core';
import { Patient_Details } from '../../../models/view-content.models/view-content.model';
import { BreadcrumbParam, BreadcrumbsComponent } from '../../home-page/breadcrumbs/breadcrumbs.component';
import { DatePipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { Params, Router } from '@angular/router';

@Component({
    selector: 'app-patient-info-details',
    templateUrl: './patient-info-details.component.html',
    styleUrls: ['./patient-info-details.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, NgIf, TranslateModule, DatePipe, CalAgePipe, DayAgoPipe],
})
export class PatientInfoDetailsComponent {
    @Input({ required: true }) vcPatientDetails: Patient_Details | undefined;
    @Input() hideCurrentPlace: boolean = false;

    constructor(private router: Router) {}

    public onClickOnBreadcrumb($event: BreadcrumbParam): void {
        const queryParams: Params = {};
        queryParams[$event.type] = $event.value;
        this.router.navigate(['/organization'], { queryParams });
    }
}
