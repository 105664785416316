import { VC_CurveGroup, VcHistoryElement } from './../../../models/view-content.models/view-content.model';
import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { ChartData, CurveComponent, initDataset } from '../../curve/curve.component';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { MatIconModule } from '@angular/material/icon';
import { v4 as uuidv4 } from 'uuid';
import { firstValueFrom } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CurveGroup, CurveItemData, CurveRow } from '../../../models/curve.model';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import * as mapper from './mapper';
import {
    VitalDialogData,
    VitalDialogFieldsToShow,
    VitalValuesComponent,
} from '../../curve/vital-values/vital-values.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { inOutExpandY } from '../../../shared/animations';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { ToolboxService } from '../../../services/toolbox.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface ExtendedCurveGroup extends CurveGroup {
    vcLocator: string;
    isExpanded: boolean;
}

//#region Types for tables which cells have values that can be clicked independently
interface CurveMultiDataItem extends Omit<CurveItemData, 'value'> {
    value: { date: string; value: string }[];
}

interface CurveMultiDataRow extends Omit<CurveRow, 'data'> {
    data: CurveMultiDataItem[];
}

interface CurveMultiDataGroup extends Omit<CurveGroup, 'rows'> {
    vcLocator: string;
    rows: CurveMultiDataRow[];
}

//#endregion

@Component({
    selector: 'app-patient-curve',
    templateUrl: './patient-curve-group.component.html',
    styleUrls: ['./patient-curve-group.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        CurveComponent,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
    ],
    animations: [inOutExpandY],
})
export class PatientCurveComponent implements OnChanges, AfterViewChecked {
    @Input() public vcGroups: VC_CurveGroup[] = [];
    @Input() patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: '',
    };

    @Output() groupsChange = new EventEmitter<VC_CurveGroup>();

    public firstDate: string = dayjs().add(-1, 'day').toISOString();
    public today = new Date().toISOString();
    public datesArray: string[] = [];
    public upperDataToShow: ExtendedCurveGroup[] = [];
    public middleDataToShow: ExtendedCurveGroup[] = [];
    public downerDataToShow: ExtendedCurveGroup[] = [];
    public isVitalSignsExpanded = true;

    /** Data for the Chart.js component */
    public curveDataToShow: ChartData['datasets'] = initDataset;
    public curveDataForTable: CurveMultiDataGroup | undefined;

    public constructor(
        private dialog: MatDialog,
        private elRef: ElementRef
    ) {}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.refresh();
    }

    public ngAfterViewChecked(): void {
        // Calculate the width of one hour after element is rendered
        const parentElement = this.elRef.nativeElement as HTMLElement;
        const parentWidth = parentElement.clientWidth;
        const hoursFirstColumn = 50;
        const oneHourCalculatedWidth = (parentWidth - 2) / (168 + hoursFirstColumn); // [parentWidth - border (2px)] divided by [168h (one week) + 50h (width for first column)]
        parentElement.style.setProperty('--one-hour-calculated-width', `${oneHourCalculatedWidth}px`);
        parentElement.style.setProperty('--hours-first-column', `${hoursFirstColumn}`);
    }

    //#region Listeners
    public onClickOnAddGroupUnderneath(group: ExtendedCurveGroup, zone: 'upper' | 'middle' | 'lower') {
        const index = this.vcGroups.findIndex((e) => e.locator === group.vcLocator);
        const newGroup: CurveGroup = {
            id: uuidv4(),
            label: 'newGroup',
            position: zone,
            status: 'normal',
            sortOrder: 0,
            rows: [],
        };
        const viewContent = this.getFreshViewContentForGroup(newGroup);
        this.vcGroups.splice(index + 1, 0, viewContent);

        this.groupsChange.emit(this.vcGroups[index]);
    }

    public onClickOnAddRow(group: ExtendedCurveGroup) {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcLocator);
        originalVcCurveGroup?.data.rows.push({
            id: uuidv4(),
            label: 'Neue Zeile',
            sortOrder: 0,
            status: 'normal',
            data: [],
        });

        this.groupsChange.emit(originalVcCurveGroup);
    }

    public async onClickOnEditGroup(group: ExtendedCurveGroup): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcLocator);
        const originalCurveGroup = originalVcCurveGroup?.data;
        if (!originalCurveGroup) {
            alert('Beim Versuch, den Namen der Gruppe zu ändern, ist ein Problem aufgetreten.');
            throw Error('Error editing curve group: no originalCurveGroup found');
        }
        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                Entfernen: 'Entfernen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                Entfernen: 'Delete',
                'Field Set': 'Field Set',
            },
        };
        const dialogRes = await this.openGroupLineEditDialog(group?.label, [], i18n);

        if (dialogRes.role === 'cancel') return;

        if (dialogRes.role === 'delete') {
            if (confirm('Möchten Sie wircklich diese Gruppe entfernen?')) originalCurveGroup.status = 'deleted';
        } else {
            originalCurveGroup.label = dialogRes.value;
        }

        this.groupsChange.emit(originalVcCurveGroup);
    }

    public async onClickOnEditRow(group: ExtendedCurveGroup, line: CurveRow): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcLocator);
        const originalGroup = originalVcCurveGroup?.data;
        const originalRow = originalGroup?.rows.find((e) => e.id === line.id);
        if (!originalRow) {
            alert('Beim Versuch, den Namen der Zeile zu ändern, ist ein Problem aufgetreten.');
            throw Error('Error editing curve row: no originalRow found');
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                Entfernen: 'Zeile entfernen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                Entfernen: 'Delete line',
                'Field Set': 'Field Set',
            },
        };
        const dialogRes = await this.openGroupLineEditDialog(line?.label, [], i18n);

        if (dialogRes.role === 'cancel') return;

        if (dialogRes.role === 'delete') {
            if (confirm('Möchten Sie wircklich diese Zeile entfernen?')) originalRow.status = 'deleted';
        } else {
            originalRow.label = dialogRes.value;
        }

        this.groupsChange.emit(originalVcCurveGroup);
    }

    public async onClickOnCell(group: ExtendedCurveGroup, row: CurveRow, data: CurveItemData): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcLocator);
        const originalGroup = originalVcCurveGroup?.data;
        const originalLine = originalGroup?.rows.find((e) => e.id === row.id);
        let originalData = originalLine?.data.find((e) => e.date === data.date);

        if (!originalLine) {
            alert('Beim Versuch, neue Daten einzugeben, ist ein Fehler aufgetreten');
            return;
        }

        if (!originalData) {
            originalData = data;
            // originalData = { ...data };
            originalLine.data.push(originalData);
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
                rows_0_data_0_value: 'Wert',
                rows_0_data_0_date: 'Datum',
                rows_0_label: 'Beschriftung Zeile',
                rows_0_sortOrder: 'Sortierungsindex',
                rows_0_status: 'Status',
                label: 'Beschriftung Gruppe',
                Label: 'Beschriftung Gruppe',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
                rows_0_data_0_value: 'Value',
                rows_0_data_0_date: 'Date',
                rows_0_label: 'Label row',
                rows_0_sortOrder: 'Sort order',
                rows_0_status: 'Status',
                label: 'Label group',
                Label: 'Label',
            },
        };
        const newData = await this.openFormDialog(
            originalData.value,
            this.extractHistoryForCell(originalVcCurveGroup?.history ?? [], group.id, row.id, data.date),
            i18n,
            row.label
        );

        if (newData.role === 'cancel') return;

        originalData.value = newData.value;
        this.groupsChange.emit(originalVcCurveGroup);
    }

    public onVitalSignChange(dataSets: ChartData['datasets']) {
        const vitalSignsGroup = this.vcGroups.find((e) => e.data.id.startsWith('vital_signs'));
        if (!vitalSignsGroup) throw Error('Error trying to store vital signs: no vitalSignsGroup found');
        // TODO: Create a group instead of throwing an error

        const mergedData = mapper.mergeVitalSignsData(dataSets);
        const rows = mapper.buildVitalSignsRow(mergedData);
        vitalSignsGroup.data.rows = rows;

        this.groupsChange.emit(vitalSignsGroup);
    }

    public onClickOnMoveDays(days: number) {
        this.firstDate = dayjs(this.firstDate).add(days, 'days').toISOString();
        this.refresh();
    }

    public onClickOnToday(): void {
        this.firstDate = dayjs().add(-1, 'days').toISOString();
        this.refresh();
    }

    public async onClickOnVitalSignTableValue(
        value: { date: string; value: string },
        row: CurveMultiDataRow
    ): Promise<void> {
        const originalGroup = this.vcGroups.find((e) => e.data.id.startsWith('vital_signs'));
        const originalRow = originalGroup?.data.rows.find((e) => e.label === row.label);
        const originalItem = originalRow?.data.find((e) => e.date === value.date);
        if (!originalItem) throw Error('Error in onClickOnVitalSignTableValue: no originalItem found');

        //#region Compose data object for the dialog
        const data: VitalDialogData = {
            index: 0,
            documentationDate: dayjs().toISOString(),
            timeStamp: originalItem.date,
            showPlusButton: true,
            history: [],
        };
        const fieldsToShow: VitalDialogFieldsToShow = {
            temperature: false,
            heartRate: false,
            respiratoryRate: false,
            bloodPressure: false,
        };
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                fieldsToShow.heartRate = true;
                data.heartRate = Number.parseFloat(originalItem.value);
                break;
            case 'Atemfrequenz':
                fieldsToShow.respiratoryRate = true;
                data.respiratoryRate = Number.parseFloat(originalItem.value);
                break;
            case 'Temperatur':
                fieldsToShow.temperature = true;
                data.temperatur = Number.parseFloat(originalItem.value);
                break;
            case 'Blutdruck':
                fieldsToShow.bloodPressure = true;
                const p = originalItem.value.split('/');
                data.bloodPressureSystolic = Number.parseFloat(p[0]);
                data.bloodPressureDiastolic = Number.parseFloat(p[1]);
                break;
        }
        data.fieldsToShow = fieldsToShow;
        //#endregion

        if (originalGroup?.history && originalRow) {
            data.history = this.extractHistoryForCell(
                originalGroup.history,
                originalGroup?.data.id,
                originalRow.id,
                originalItem.date
            );
        }

        // Open dialog and wait for response
        const result = await this.openVitalSignsInputDialog(data);

        switch (result.role) {
            case 'save':
                originalItem.date = result.timeStamp;
                switch (originalRow?.label) {
                    case 'Herzfrequenz':
                        originalItem.value = result.heartRate?.toString() ?? '';
                        break;
                    case 'Atemfrequenz':
                        originalItem.value = result.respiratoryRate?.toString() ?? '';
                        break;
                    case 'Temperatur':
                        originalItem.value = result.temperatur?.toString() ?? '';
                        break;
                    case 'Blutdruck':
                        originalItem.value = `${
                            result.bloodPressureSystolic?.toString() ?? ''
                        }/${result.bloodPressureDiastolic?.toString() ?? ''}`;
                        break;
                }

                this.groupsChange.emit(originalGroup);
                break;
            case 'add':
                const addRes = await this.openVitalSignsInputDialog();
                if (addRes.role === 'save') {
                    this.addEntryToVitalSingsGroup(originalGroup, addRes);
                }
                originalGroup?.data.rows[0].data.sort((d1, d2) => d1.date.localeCompare(d2.date));
                this.groupsChange.emit(originalGroup);
                break;
        }
    }

    public async onClickOnDownload(): Promise<void> {
        try {
            const pdffileName = `${this.patientNameAndDob.lastName}-${this.patientNameAndDob.firstName}-${dayjs().format('DD.mm.YYYY')}.pdf`;
            // const pdffileName = `test.pdf`;

            const docDefinition: TDocumentDefinitions = {
                content: [
                    { text: 'Patienten Informationen', style: 'subheader' },

                    this.createPatientDetailsTable(),

                    { text: 'Basisinformationen', style: 'subheader' },
                    this.createBasisInformationTable(),

                    { text: 'Vitalwerte', style: 'subheader' },
                    this.createVitalSignsTable(),

                    // // Patient Data Section
                    //     // Patient Data Section
                    //     { text: 'Patient Data', style: 'subheader' },
                    //     this.createPatientHistorySections(),
                ],

                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 14,
                        bold: true,
                        margin: [0, 10, 0, 5],
                    },
                },
            };

            // Create and download the PDF
            pdfMake.createPdf(docDefinition).download(pdffileName);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    }

    public async onClickOnVitalEmptySignsCell(row: CurveMultiDataRow, d: CurveMultiDataItem): Promise<void> {
        const originalGroup = this.vcGroups.find((e) => e.data.id.startsWith('vital_signs'));
        const originalRow = originalGroup?.data.rows.find((e) => e.label === row.label);

        //#region Compose data object for the dialog
        const now = dayjs();
        let timeStamp = dayjs(d.date);
        timeStamp = timeStamp.hour(now.hour()).minute(now.minute()).second(now.second()).millisecond(now.millisecond());
        const data: VitalDialogData = {
            index: 0,
            documentationDate: dayjs().toISOString(),
            timeStamp: timeStamp.toISOString(),
            showPlusButton: false,
            history: [],
        };
        const fieldsToShow: VitalDialogFieldsToShow = {
            temperature: false,
            heartRate: false,
            respiratoryRate: false,
            bloodPressure: false,
        };
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                fieldsToShow.heartRate = true;
                break;
            case 'Atemfrequenz':
                fieldsToShow.respiratoryRate = true;
                break;
            case 'Temperatur':
                fieldsToShow.temperature = true;
                break;
            case 'Blutdruck':
                fieldsToShow.bloodPressure = true;
                break;
        }
        data.fieldsToShow = fieldsToShow;
        //#endregion

        // Open dialog and wait for response
        const result = await this.openVitalSignsInputDialog(data);

        if (result.role === 'cancel') return;

        let value: string | undefined = '';
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                value = result.heartRate?.toString();
                break;
            case 'Atemfrequenz':
                value = result.respiratoryRate?.toString();
                break;
            case 'Temperatur':
                value = result.temperatur?.toString();
                break;
            case 'Blutdruck':
                value = `${result.bloodPressureSystolic}/${result.bloodPressureDiastolic}`;
                break;
        }

        if (!value) throw Error('Error saving vital signs: no value found');
        originalRow?.data.push({
            id: uuidv4(),
            date: result.timeStamp,
            value,
        });

        this.groupsChange.emit(originalGroup);
    }

    public onClickOnMoveRow(move: number, row: CurveRow, group: ExtendedCurveGroup) {
        const originalGroup = this.getOriginalVCGroup(group.vcLocator);
        if (!originalGroup) throw Error('Error moving row in group: no originalGroup found');

        let rowsNew = group.rows.map((e, i) => ({ ...e, sortOrder: i }));
        const oldIndex = rowsNew.findIndex((e) => e.id === row.id);
        const elementToMove = rowsNew.splice(oldIndex, 1)[0];

        let newIndex = oldIndex + move;
        if (newIndex < 0) newIndex = 0;
        if (newIndex > group.rows.length - 1) newIndex = group.rows.length - 1;

        rowsNew.splice(newIndex, 0, elementToMove);
        rowsNew = rowsNew.map((e, i) => ({ ...e, sortOrder: i }));

        originalGroup.data.rows = rowsNew;
        this.refresh();
        this.groupsChange.emit(originalGroup);
    }

    //#endregion

    public isToday(d: string): boolean {
        return dayjs().isSame(d, 'day');
    }

    public isWeekend(d: string): boolean {
        const day = dayjs(d).day();
        return day === 0 || day === 6;
    }

    /** Inserts in place! */
    public addEntryToVitalSingsGroup(originalGroup: VC_CurveGroup | undefined, addRes: VitalDialogData): VC_CurveGroup {
        if (!originalGroup?.data.rows)
            throw Error('Error adding entries to vital signs group: originalGroup?.data.rows is falsy');

        for (const row of originalGroup?.data.rows) {
            let value = '';
            switch (row.label) {
                case 'Herzfrequenz':
                    if (!addRes.heartRate) continue;
                    value = addRes.heartRate?.toString() ?? '';
                    break;
                case 'Atemfrequenz':
                    if (!addRes.respiratoryRate) continue;
                    value = addRes.respiratoryRate?.toString() ?? '';
                    break;
                case 'Temperatur':
                    if (!addRes.temperatur) continue;
                    value = addRes.temperatur?.toString() ?? '';
                    break;
                case 'Blutdruck':
                    if (!addRes.bloodPressureSystolic && !addRes.bloodPressureDiastolic) continue;
                    value = `${
                        addRes.bloodPressureSystolic?.toString() ?? ''
                    }/${addRes.bloodPressureDiastolic?.toString() ?? ''}`;
                    break;
            }

            row.data.push({ id: uuidv4(), date: addRes.timeStamp, value });
        }

        return originalGroup;
    }

    private createPatientDetailsTable() {
        return {
            table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    // Header row with bold white text
                    [
                        { text: 'Name', bold: true, color: 'white' },
                        { text: 'DOB', bold: true, color: 'white' },
                        { text: 'Geschlecht', bold: true, color: 'white' },
                        { text: 'Station', bold: true, color: 'white' },
                        { text: 'Zimmer', bold: true, color: 'white' },
                        { text: 'Bett', bold: true, color: 'white' },
                    ],
                    // Data row with patient details
                    [
                        {
                            text: `${this.patientNameAndDob.firstName || 'N/A'} ${this.patientNameAndDob.lastName || 'N/A'}`,
                        },
                        { text: this.patientNameAndDob.dob || 'N/A' },
                        { text: this.patientNameAndDob.gender || 'N/A' },
                        { text: this.patientNameAndDob.ward || 'N/A' },
                        { text: this.patientNameAndDob.room || 'N/A' },
                        { text: this.patientNameAndDob.bed || 'N/A' },
                    ],
                ],
            },
            layout: {
                fillColor: (rowIndex: number) => (rowIndex === 0 ? '#a394ca' : null), // Color for header row
                hLineWidth: () => 0.5,
                vLineWidth: () => 0.5,
                hLineColor: () => '#CCCCCC',
                vLineColor: () => '#CCCCCC',
            },
            margin: [0, 10, 0, 15],
            pageSize: 'A4',
            pageOrientation: 'landscape',
            fontSize: 10,
        };
    }

    //#endregion

    // Basisinformation
    private createBasisInformationTable(): any {
        const dateHeaders = this.datesArray.map((date: string) => {
            return {
                text: dayjs(date).format('DD.MM.YYYY'),
                alignment: 'center',
                bold: true,
                fillColor: '#a394ca',
                color: 'white',
            };
        });

        console.log(dateHeaders);
        console.log(this.datesArray);

        const createInfoRow = (label: string, data: string[]) => [
            { text: label, bold: true },
            ...data.map((item) => ({ text: item })),
        ];
        const basisInfoData = [
            { label: 'Notizen', data: this.getBasisInformationData('Notizen') },
            { label: 'Termine', data: this.getBasisInformationData('Termine') },
            { label: 'Diagnostik', data: this.getBasisInformationData('Diagnostik') },
            { label: 'Therapie', data: this.getBasisInformationData('Therapie') },
        ];

        // Create rows dynamically using the helper function
        const dataRows = basisInfoData.map((info) => createInfoRow(info.label, info.data));

        return {
            table: {
                widths: Array(8).fill('*'),
                body: [
                    [
                        {
                            text: 'Informationen / Datum',
                            bold: true,
                            fillColor: '#a394ca',
                            color: 'white',
                        },
                        ...dateHeaders,
                    ],
                    ...dataRows,
                ],
            },
            layout: {
                hLineWidth: () => 0.5,
                vLineWidth: () => 0.5,
                hLineColor: () => '#CCCCCC',
                vLineColor: () => '#CCCCCC',
            },
            margin: [0, 10, 0, 15],
            pageSize: 'A4',
            pageOrientation: 'landscape',
            fontSize: 8,
            content: [{ text: 'Header Text', fontSize: 12, margin: [0, 0, 0, 10] }],
        };
    }

    private getBasisInformationData(category: string): string[] {
        const basisGroup = this.vcGroups.find((vcGroup) => vcGroup.locator.startsWith('case.curve.group.basisGroup'));

        if (!basisGroup) {
            console.warn(`Basis group not found for category: ${category}`);
            return Array(7).fill('');
        }

        const basisRow = basisGroup.data.rows.find((row) => row.label === category);
        if (!basisRow) {
            console.warn(`No data found for category: ${category}`);
            return Array(7).fill('');
        }

        const basisInfoData = this.datesArray.map((date) => {
            const entry = basisRow.data.find((data) => data.date.includes(date));
            return entry?.value ?? '';
        });

        return basisInfoData.slice(0, 7);
    }

    private createVitalSignsTable(): any {
        const dateHeaders = this.datesArray.map((date: string) => {
            return {
                text: dayjs(date).format('DD.MM.YYYY'),
                alignment: 'center',
                bold: true,
                fillColor: '#a394ca',
                color: 'white',
            };
        });

        console.log(dateHeaders);
        console.log(this.datesArray);

        const createVitalSignRow = (label: string, data: string[]) => [{ text: label, bold: true }, ...data];

        const heartRateRow = createVitalSignRow('Herzfrequenz', this.getVitalSignData('Herzfrequenz'));
        const bloodPressureRow = createVitalSignRow('Blutdruck', this.getVitalSignData('Blutdruck'));
        const temperatureRow = createVitalSignRow('Temperatur', this.getVitalSignData('Temperatur'));
        const respiratoryRateRow = createVitalSignRow('Atemfrequenz', this.getVitalSignData('Atemfrequenz'));

        return {
            table: {
                widths: Array(8).fill('*'), // 8 equal-width columns
                body: [
                    [
                        { text: 'Informationen / Datum', bold: true, fillColor: '#a394ca', color: 'white' },
                        ...dateHeaders,
                    ],
                    heartRateRow,
                    bloodPressureRow,
                    temperatureRow,
                    respiratoryRateRow,
                ],
            },
            layout: {
                hLineWidth: () => 0.5,
                vLineWidth: () => 0.5,
                hLineColor: () => '#CCCCCC',
                vLineColor: () => '#CCCCCC',
            },
            margin: [0, 10, 0, 15],
            pageSize: 'A4',
            pageOrientation: 'landscape',
            fontSize: 8,
            content: [{ text: 'Vital Signs Report', fontSize: 12, margin: [0, 0, 0, 10] }],
        };
    }

    private getVitalSignData(vitalSign: string): string[] {
        const vitalSignGroup = this.vcGroups.find((vcGroup) =>
            vcGroup.locator.startsWith('case.curve.group.vitalSigns')
        );

        if (!vitalSignGroup) {
            console.warn(`Vital sign group not found for: ${vitalSign}`);
            return Array(7).fill('');
        }

        const vitalSignRow = vitalSignGroup.data.rows.find((row) => row.label === vitalSign);
        if (!vitalSignRow) {
            console.warn(`No data found for vital sign: ${vitalSign}`);
            return Array(7).fill('');
        }

        const vitalSignData = this.datesArray.map((date) => {
            const entry = vitalSignRow.data.find((data) => data.date.includes(date));
            return entry?.value ?? '';
        });

        return vitalSignData.slice(0, 7); // Return only the last 7 days' data
    }

    private refresh(): void {
        // Build an array with 7 dates starting on the current this.firstDate
        const d = dayjs(this.firstDate);
        this.datesArray = [];
        for (let i = 0; i < 7; i++) {
            this.datesArray.push(d.add(i, 'day').format('YYYY-MM-DD'));
        }

        this.upperDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'upper')
                .map((e) => ({ ...e.data, vcLocator: e.locator, isExpanded: true })),
            this.datesArray,
            'upper'
        );

        this.middleDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'middle')
                .map((e) => ({ ...e.data, vcLocator: e.locator, isExpanded: true })),
            this.datesArray,
            'middle'
        );

        const vitalSignsGroup = this.vcGroups.find((e) => e.locator.startsWith('case.curve.group.vitalSigns'));
        // const vitalSignsGroup = this.vcGroups.find((e) => e.data.id.startsWith('vital_signs'));

        if (vitalSignsGroup) {
            this.curveDataForTable = this.buildMultiValueGroup(
                {
                    ...vitalSignsGroup.data,
                    vcLocator: vitalSignsGroup.locator,
                    isExpanded: true,
                },
                this.datesArray,
                'middle'
            );
            this.curveDataToShow = mapper.mapVsGroup2ChartDataDatasets(vitalSignsGroup);
        }

        this.downerDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'lower')
                .map((e) => ({ ...e.data, vcLocator: e.locator, isExpanded: true })),
            this.datesArray,
            'lower'
        );
    }

    /** Builds an ExtendedGroup with all dates complete (no missing dates, even dates with no data will be represented) */
    private buildSimpleGroup(
        groups: ExtendedCurveGroup[],
        datesArray: string[],
        position: 'upper' | 'middle' | 'lower'
    ): ExtendedCurveGroup[] {
        const res: ExtendedCurveGroup[] = [];
        for (const g of groups) {
            const newGroup: ExtendedCurveGroup = {
                id: g.id,
                vcLocator: g.vcLocator,
                label: g.label,
                rows: [],
                position,
                sortOrder: g.sortOrder,
                status: g.status,
                isExpanded: true,
            };

            // Build the lines in the group from the data in the original group and the date of the datesArray
            for (const l of g.rows) {
                const newLine: CurveRow = {
                    id: l.id,
                    label: l.label,
                    sortOrder: l.sortOrder,
                    status: l.status,
                    data: [],
                };

                for (const date of datesArray) {
                    newLine.data.push({
                        id: uuidv4(),
                        date,
                        value:
                            l.data
                                .filter((e) => {
                                    const test = dayjs(e.date).isSame(dayjs(date), 'day');
                                    return test;
                                })
                                .map((i) => i.value)
                                .join('; ') ?? '',
                    });
                }

                newGroup.rows.push(newLine);
            }

            res.push(newGroup);
        }

        return res;
    }

    /** Similar to this.buildSimpleGroup, but instead of string values, will host an array of objects, each with date and value (so you can have several entries in the same date) */
    private buildMultiValueGroup(
        group: ExtendedCurveGroup,
        datesArray: string[],
        position: 'upper' | 'middle' | 'lower'
    ): CurveMultiDataGroup {
        const newGroup: CurveMultiDataGroup = {
            id: group.id,
            vcLocator: group.vcLocator,
            label: group.label,
            rows: [],
            position,
            sortOrder: group.sortOrder,
            status: group.status,
        };

        // Build the lines in the group from the data in the original group and the date of the datesArray
        for (const l of group.rows) {
            const newLine: CurveMultiDataRow = {
                id: l.id,
                label: l.label,
                sortOrder: l.sortOrder,
                status: l.status,
                data: [],
            };

            for (const date of datesArray) {
                const dataForDay = l.data
                    .filter((e) => {
                        const test = dayjs(e.date).isSame(dayjs(date), 'day');
                        return test;
                    })
                    .sort((e1, e2) => e1.date.localeCompare(e2.date))
                    .map((e) => ({ date: e.date, value: e.value }));

                newLine.data.push({
                    id: uuidv4(),
                    date,
                    value: dataForDay,
                });
            }

            newGroup.rows.push(newLine);
        }

        return newGroup;
    }

    private getOriginalVCGroup(locator: string): VC_CurveGroup | undefined {
        return this.vcGroups.find((e) => e.locator === locator);
    }

    private async openFormDialog(
        oldValue: string,
        history: VcHistoryElement<CurveGroup>[],
        i18n: FormioRendererI18n,
        title: string
    ): Promise<{ role: string; value: string }> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: 'form_curve_input.json',
                patient_info: this.patientNameAndDob,
                form_data: { data: { textField: oldValue } },
                history,
                form_title: title,
                viewContentI18n: i18n,
                hideHistoryFields: ['position', 'status', 'Status', 'Sortierungsindex', 'sortOrder'],
            },
            panelClass: 'patient-overview-dialog-container',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res) return { role: 'cancel', value: '' };

        const value: string = res.data.find((e: { key: string; value: string }) => e.key === 'textField')?.value;

        return { role: res.role, value };
    }

    private async openGroupLineEditDialog(
        oldValue: string,
        history: VcHistoryElement<CurveGroup>[],
        i18n: FormioRendererI18n
    ): Promise<{ role: string; value: string }> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: 'form_curve_group_row_name.json',
                patient_info: this.patientNameAndDob,
                form_data: { data: { textField: oldValue } },
                history,
                viewContentI18n: i18n,
            },
            panelClass: 'patient-overview-dialog-container',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res) return { role: 'cancel', value: '' };

        const value: string = res.data.find((e: { key: string; value: string }) => e.key === 'textField')?.value;

        return { role: res.role, value };
    }

    private getFreshViewContentForGroup(group: CurveGroup): VC_CurveGroup {
        const id = uuidv4();
        const res: VC_CurveGroup = {
            id: -1,
            locator: `case.curve.group.${uuidv4()}`,
            main_owner_job_type: 'doctor', // TODO: Change the the type of the current user
            status: 'not_final',
            related_patient_id: '', // TODO: Fix this
            related_case_id: '', // TODO: Fix this
            data: group,
            owners: [], // TODO: Fix this
            owner_departments: [], // TODO: Fix this
            created_at: new Date().toISOString(),
            // TODO: Add form and i18n
        };

        return res;
    }

    private extractHistoryForCell(
        allHistory: VcHistoryElement<CurveGroup>[],
        groupId: string,
        rowId: string,
        date: string
    ): VcHistoryElement<CurveGroup>[] {
        const res = allHistory
            // Filter to include only those history elements whose CurveGroup matches the specified groupId
            .filter((historyElement) => historyElement.data.id === groupId)
            .map((historyElement) => {
                // Filter rows that match the specified rowId
                const filteredRows = historyElement.data.rows
                    .filter((row) => row.id === rowId)
                    .map((row) => ({
                        ...row,
                        // Further filter the row's data to include only those items with the specified date
                        data: row.data.filter((item) => item.date === date),
                    }))
                    // Keep only rows that have at least one data item after filtering by date
                    .filter((row) => row.data.length > 0);

                // If any rows match the criteria, return a new history element with the filtered rows
                if (filteredRows.length > 0) {
                    return {
                        ...historyElement,
                        data: {
                            ...historyElement.data,
                            rows: filteredRows,
                        },
                    };
                } else {
                    // If no rows match the criteria, return null
                    return null;
                }
            })
            // Filter out any null entries, ensuring only matching history elements are returned
            .filter((historyElement) => historyElement !== null) as VcHistoryElement<CurveGroup>[];

        if (res) res.sort((e1, e2) => e2.modifiedAt.localeCompare(e1.modifiedAt));

        return res;
    }

    private openVitalSignsInputDialog(data: VitalDialogData = {} as VitalDialogData): Promise<VitalDialogData> {
        return new Promise<VitalDialogData>((resolve) => {
            const dialogRef = this.dialog.open(VitalValuesComponent, {
                disableClose: true,
                width: '600px',
                data,
            });

            const diagSub = dialogRef.afterClosed().subscribe((result: VitalDialogData) => {
                resolve(result);
                diagSub.unsubscribe();
            });
        });
    }

    /** Generates a csv string with all the data from groups and vital-signs group (without history) */
    private vcGroupsToCsv(): string {
        let csv = '';

        // Build an array with all dates from not vital sign groups sorted
        const datesSet = new Set<string>();
        for (const g of this.vcGroups) {
            if (g.data.id.startsWith('vital_signs')) continue;

            for (const r of g.data.rows) {
                for (const d of r.data) {
                    datesSet.add(d.date.substring(0, 10));
                }
            }
        }
        const datesArray = Array.from(datesSet).sort((d1, d2) => d1.localeCompare(d2));

        // Build an array with all date-times from vital signs
        const dateTimesSet = new Set<string>();
        const vitalSignsGroup = this.vcGroups.find((g) => g.data.id.startsWith('vital_signs'));
        if (vitalSignsGroup) {
            for (const r of vitalSignsGroup.data.rows) {
                for (const d of r.data) {
                    dateTimesSet.add(d.date);
                }
            }
        }
        const dateTimesArray = Array.from(dateTimesSet).sort((d1, d2) => d1.localeCompare(d2));

        // Add the patient details
        const largestLength = datesArray.length > dateTimesArray.length ? datesArray.length : dateTimesArray.length;
        const commaString = new Array(largestLength).join(','); // A string with the necessary amount commas to complete the patient details lines
        csv = `${csv}Patientenvorname,${this.patientNameAndDob.firstName}${commaString}\n`;
        csv = `${csv}Patientennachname,${this.patientNameAndDob.lastName}${commaString}\n`;
        csv = `${csv}Geburtsdatum,${this.patientNameAndDob.dob}${commaString}\n`;
        csv = `${csv}Geschlecht,${this.patientNameAndDob.gender}${commaString}\n`;
        csv = `${csv}Dienstleistungseinheit,${this.patientNameAndDob.ward}${commaString}\n`;
        csv = `${csv}Raum,${this.patientNameAndDob.room}${commaString}\n`;
        csv = `${csv}Ort,${this.patientNameAndDob.bed}${commaString}\n`;

        // Add empty row
        csv = `${csv},${commaString}\n`; // Here we need a comma more than commaString

        // Add the data from the non-vital-signs groups
        const missingCommasForNormal =
            dateTimesArray.length - datesArray.length > 0
                ? new Array(dateTimesArray.length - datesArray.length).join(',')
                : '';
        // Add dates row for non-vital-signs data
        csv = `${csv},${datesArray.join(',')}${missingCommasForNormal}`;
        for (const g of this.vcGroups) {
            if (g === vitalSignsGroup) continue;
            for (const r of g.data.rows) {
                let row = `${r.label}`;
                for (const d of datesArray) {
                    const value = r.data.find((e) => e.date === d);
                    row = `${row},${value?.value ?? ''}`;
                }

                csv = `${csv}\n${row}${missingCommasForNormal}`;
            }
        }

        // Add the data from the vital-signs group
        const missingCommasForVital =
            datesArray.length - dateTimesArray.length > 0
                ? new Array(datesArray.length - dateTimesArray.length + 1).join(',')
                : '';
        csv = `${csv}\n,${commaString}`; // Here we need a comma more than commaString
        csv = `${csv}\n,${dateTimesArray}${missingCommasForVital}`;
        if (vitalSignsGroup) {
            for (const r of vitalSignsGroup.data.rows) {
                let row = `${r.label}`;
                for (const d of dateTimesArray) {
                    const value = r.data.find((e) => e.date === d);
                    row = `${row},${value?.value ?? ''}`;
                }

                csv = `${csv}\n${row}${missingCommasForVital}`;
            }
        }

        return csv;
    }
}
