import { Component, OnInit } from '@angular/core';
import { DeviceFacadeService } from '../../services/facades/device-facade.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { MatTooltip } from '@angular/material/tooltip';
import { AlertService } from '../../services/alert.service';
import { Preferences } from '@capacitor/preferences';
import { LS_DEVICE } from '../../shared/constants';

@Component({
    selector: 'app-device-registered-indicator',
    templateUrl: './device-registered-indicator.component.html',
    styleUrls: ['./device-registered-indicator.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatTooltip],
})
export class DeviceRegisteredIndicatorComponent implements OnInit {
    public isRegistered = false;
    public deviceId: string | undefined;
    private device$ = this.deviceFacade.device$;
    private accessToken: string | undefined;

    constructor(
        private deviceFacade: DeviceFacadeService,
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private alert: AlertService
    ) {
        this.accessFacade.userTokens$.subscribe((t) => {
            if (t.token?.access_token) {
                this.accessToken = t.token?.access_token;
            }
        });
    }

    async ngOnInit(): Promise<void> {
        try {
            // Gerät überprüfen, ob es registriert ist
            this.device$.subscribe(async (device) => {
                const deviceId = device?.device?.deviceId;

                if (this.accessToken && deviceId) {
                    this.deviceId = deviceId;
                    this.isRegistered = await this.api.isDeviceLinked(deviceId, this.accessToken);

                    if (!this.isRegistered) {
                        await this.alert.show({
                            header: 'Gerät ist nicht registriert',
                            message:
                                'Dein Gerät ist nicht registriert!<br>' +
                                'Ohne Registrierung kannst du keine Daten empfangen oder Aktualisierungen erhalten.',
                            buttons: [{ role: 'cancel', text: 'OK' }],
                        });
                    }
                }
            });

            const localDeviceData = JSON.parse((await Preferences.get({ key: LS_DEVICE }))?.value ?? 'null');

            if (!localDeviceData?.deviceId) {
                await this.alert.show({
                    header: 'Gerät ist nicht registriert',
                    message:
                        'Dein Gerät ist nicht registriert!<br>' +
                        'Ohne Registrierung kannst du keine Daten empfangen oder Aktualisierungen erhalten.',
                    buttons: [{ role: 'cancel', text: 'OK' }],
                });
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }
}
