{
    "name": "cp2frontend",
    "version": "0.0.0",
    "author": {
        "name": "Jose Garces, Julian Schmitd, Julian Müller, Björn Kubon, Xiaoyan Hou - Lohmann & Birkner Health Care Cosnulting GmbH & triosys GmbH",
        "email": "mueller@lohmann-birkner.de",
        "url": "https://www.lohmann-birkner.de/datenschutzerklaerung/"
    },
    "scripts": {
        "ionic-serve": "ionic serve -c localhost",
        "ionic-serve-with-dev": "ionic serve -c development",
        "ionic-serve-with-prod": "ionic serve -c prod",
        "ionic-serve-with-test": "ionic serve -c test",
        "ionic-serve-with-dev-org": "ionic serve -c development-org",
        "ionic-serve-with-prod-org": "ionic serve -c prod-org",
        "ionic-serve-with-test-org": "ionic serve -c test-org",
        "ionic-serve-with-demo-org": "ionic serve -c demo-org",
        "ionic-serve-with-deeplink": "ionic serve -c app-deeplink",
        "ionic-serve-with-vivantes": "ionic serve -c vivantes",
        "build-dev": "ionic build -c development",
        "build-prod": "ionic build -c prod",
        "build-test": "ionic build -c test",
        "build-dev-org": "ionic build -c development-org",
        "build-prod-org": "ionic build -c prod-org",
        "build-test-org": "ionic build -c test-org",
        "build-demo-org": "ionic build -c demo-org",
        "build-with-deeplink": "ionic build -c app-deeplink",
        "build-vivantes": "ionic build -c vivantes",
        "sync": "npx cap sync",
        "ionic:android": "npm run remove:sql:wasm && ionic capacitor build android --configuration=app-deeplink",
        "ionic:ios": "npm run remove:sql:wasm && ionic capacitor build ios",
        "ios": "npm run sync && ionic capacitor open ios",
        "open_android": "npx cap copy && npx cap open android",
        "open_ios": "npx cap copy && npx cap open ios",
        "livereload_android": "npm run remove:sql:wasm && ionic cap run android -l --external --open",
        "livereload_ios": "npm run remove:sql:wasm && ionic cap run ios -l --external --open",
        "ionic:serve:before": "npm run copy:sql:wasm",
        "copy:sql:wasm": "copyfiles -u 3 node_modules/sql.js/dist/sql-wasm.wasm src/assets",
        "remove:sql:wasm": "rimraf src/assets/sql-wasm.wasm",
        "electron-build-app": "cd electron && npm install && cd .. && ng build -c development && npx cap sync @capacitor-community/electron && npx cap copy @capacitor-community/electron && cd electron && npm run build && npm run electron-build",
        "electron-build-dist": "cd electron && npm install && cd .. && ng build -c development && npx cap sync @capacitor-community/electron && npx cap copy @capacitor-community/electron && cd electron && npm run build && npm run electron-dist",
        "prebuild-dev": "node generate-build-info.js",
        "prebuild-prod": "node generate-build-info.js",
        "prebuild-test": "node generate-build-info.js",
        "prebuild-dev-org": "node generate-build-info.js",
        "prebuild-prod-org": "node generate-build-info.js",
        "prebuild-test-org": "node generate-build-info.js",
        "prebuild-with-deeplink": "node generate-build-info.js"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "17.2.1",
        "@angular/cdk": "17.2.1",
        "@angular/common": "17.2.1",
        "@angular/compiler": "17.2.1",
        "@angular/core": "17.2.1",
        "@angular/elements": "17.2.1",
        "@angular/forms": "17.2.1",
        "@angular/material": "17.2.1",
        "@angular/material-experimental": "17.2.1",
        "@angular/platform-browser": "17.2.1",
        "@angular/platform-browser-dynamic": "17.2.1",
        "@angular/router": "17.2.1",
        "@angular/service-worker": "17.2.1",
        "@babybeet/angular-tooltip": "1.1.1",
        "@capacitor-community/electron": "^5.0.1",
        "@capacitor-community/sqlite": "5.6.3",
        "@capacitor-mlkit/barcode-scanning": "^5.3.0",
        "@capacitor/android": "5.7.0",
        "@capacitor/app": "5.0.7",
        "@capacitor/browser": "^5.2.0",
        "@capacitor/core": "5.7.4",
        "@capacitor/device": "5.0.7",
        "@capacitor/haptics": "5.0.7",
        "@capacitor/ios": "5.7.0",
        "@capacitor/keyboard": "5.0.8",
        "@capacitor/network": "^5.0.8",
        "@capacitor/preferences": "5.0.7",
        "@capacitor/status-bar": "5.0.7",
        "@capacitor/toast": "^5.0.7",
        "@cornerstonejs/calculate-suv": "^1.1.0",
        "@cornerstonejs/core": "^1.86.0",
        "@cornerstonejs/dicom-image-loader": "^1.86.0",
        "@cornerstonejs/streaming-image-volume-loader": "^1.86.0",
        "@cornerstonejs/tools": "^1.86.0",
        "@formio/angular": "7.0.0",
        "@ionic/angular": "7.8.1",
        "@ionic/pwa-elements": "^3.2.2",
        "@lohmann-birkner/luic": "^0.9.8",
        "@material-design-icons/font": "^0.14.13",
        "@ngrx/effects": "^17.0.1",
        "@ngrx/schematics": "^17.1.0",
        "@ngrx/store": "^17.0.1",
        "@ngrx/store-devtools": "^17.0.1",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@rxfx/bus": "^1.1.0",
        "@schematics/angular": "^17.0.10",
        "@tailwindcss/typography": "^0.5.15",
        "@tiptap/core": "^2.5.9",
        "@tiptap/extension-color": "^2.5.9",
        "@tiptap/extension-font-family": "^2.8.0",
        "@tiptap/extension-text-align": "^2.5.9",
        "@tiptap/extension-text-style": "^2.5.9",
        "@tiptap/extension-underline": "^2.5.9",
        "@tiptap/starter-kit": "^2.5.9",
        "angular-oauth2-oidc": "^17.0.2",
        "angular-oauth2-oidc-jwks": "^17.0.2",
        "angularx-qrcode": "^17.0.0",
        "chart.js": "^4.3.0",
        "chartjs-adapter-dayjs-4": "^1.0.4",
        "chartjs-plugin-annotation": "^3.0.1",
        "crypto-js": "^4.2.0",
        "dayjs": "^1.11.10",
        "dicom-parser": "^1.8.21",
        "diff-match-patch": "^1.0.5",
        "event-source-polyfill": "^1.0.31",
        "gridstack": "10.3.1",
        "html-to-pdfmake": "2.5.13",
        "html2canvas": "^1.4.1",
        "jeep-sqlite": "2.6.2",
        "jose": "^5.2.1",
        "ng-event-bus": "^6.0.0",
        "ng2-charts": "^6.0.1",
        "ngx-tiptap": "^10.1.0",
        "pdfjs-dist": "^4.5.136",
        "pdfmake": "0.2.14",
        "rxjs": "~7.8.0",
        "sql.js": "1.10.2",
        "tslib": "^2.3.0",
        "uuid": "^9.0.1",
        "zone.js": "~0.14.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "17.2.1",
        "@angular-devkit/schematics": "17.2.1",
        "@angular-eslint/builder": "17.2.1",
        "@angular-eslint/eslint-plugin": "17.2.1",
        "@angular-eslint/eslint-plugin-template": "17.2.1",
        "@angular-eslint/schematics": "17.2.1",
        "@angular-eslint/template-parser": "17.2.1",
        "@angular/cli": "17.2.1",
        "@angular/compiler-cli": "17.2.1",
        "@angular/language-service": "17.2.1",
        "@capacitor/cli": "5.7.0",
        "@ionic/angular-toolkit": "11.0.1",
        "@ionic/cli": "^7.2.0",
        "@ngrx/store-devtools": "^17.0.1",
        "@types/crypto-js": "^4.2.2",
        "@types/diff-match-patch": "^1.0.36",
        "@types/event-source-polyfill": "^1.0.5",
        "@types/html-to-pdfmake": "^2.4.4",
        "@types/jasmine": "~5.1.0",
        "@types/node": "20.11.24",
        "@types/pdfmake": "^0.2.9",
        "@types/qrcode": "^1.5.5",
        "@types/uuid": "^9.0.8",
        "@typescript-eslint/eslint-plugin": "^6.0.0",
        "@typescript-eslint/parser": "^6.0.0",
        "copyfiles": "^2.4.1",
        "eslint": "^7.26.0",
        "eslint-plugin-import": "2.22.1",
        "eslint-plugin-jsdoc": "30.7.6",
        "eslint-plugin-prefer-arrow": "1.2.2",
        "jasmine-core": "~5.1.0",
        "jasmine-spec-reporter": "~5.0.0",
        "karma": "~6.4.0",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.0",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "~2.1.0",
        "prettier": "^3.3.3",
        "rimraf": "^5.0.5",
        "tailwindcss": "^3.4.3",
        "ts-node": "^8.3.0",
        "typescript": "~5.2.2"
    }
}
