import { AccessFacadeService } from '../../../services/facades/access-facade.service';
import { Cp2ApiService } from '../../../services/cp2-api.service';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { DynamicListComponent } from '../../../components/data-interaction/dynamic-list/dynamic-list.component';
import { DayAgoPipe } from '../../../pipes/day-ago.pipe';
import { FormioModule } from '@formio/angular';
import { FormioRendererComponent } from '../../../components/data-interaction/formio-renderer/formio-renderer.component';
import { PatientListGroup } from '../../../models/view-content.models/view-content.model';
import { ToolboxService } from '../../../services/toolbox.service';

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-test-sandbox',
    templateUrl: './test-sandbox.component.html',
    standalone: true,
    styleUrls: ['./test-sandbox.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        DynamicListComponent,
        DayAgoPipe,
        FormioModule,
        FormioRendererComponent,
    ],
})
export class TestSandboxComponent implements OnInit {
    private static readonly TAG = 'TestSandboxComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public caseList: PatientListGroup[] = [];

    public form = {
        display: 'form',
        settings: {
            pdf: {
                id: '1ec0f8ee-6685-5d98-a847-26f67b67d6f0',
                src: 'https://files.form.io/pdf/5692b91fd1028f01000407e3/file/1ec0f8ee-6685-5d98-a847-26f67b67d6f0',
            },
        },
        components: [
            {
                label: 'Text Field',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'textFieldkjhkljh',
                type: 'textfield',
                input: true,
            },
            {
                label: 'Columns',
                columns: [
                    {
                        components: [
                            {
                                label: 'Text Field',
                                applyMaskOn: 'change',
                                tableView: true,
                                validateWhenHidden: false,
                                key: 'textField',
                                type: 'textfield',
                                input: true,
                            },
                            {
                                label: 'Text Area',
                                applyMaskOn: 'change',
                                autoExpand: false,
                                tableView: true,
                                validateWhenHidden: false,
                                key: 'textArea',
                                type: 'textarea',
                                input: true,
                            },
                        ],
                        width: 9,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 6,
                    },
                    {
                        components: [
                            {
                                label: 'Text Area',
                                applyMaskOn: 'change',
                                autoExpand: false,
                                tableView: true,
                                validateWhenHidden: false,
                                key: 'textArea1',
                                type: 'textarea',
                                input: true,
                            },
                        ],
                        width: 3,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 6,
                    },
                    {
                        components: [
                            {
                                label: 'Password',
                                applyMaskOn: 'change',
                                tableView: false,
                                validateWhenHidden: false,
                                key: 'password',
                                type: 'password',
                                input: true,
                                protected: true,
                            },
                            {
                                label: 'Checkbox',
                                tableView: false,
                                validateWhenHidden: false,
                                key: 'checkbox',
                                type: 'checkbox',
                                input: true,
                            },
                        ],
                        size: 'md',
                        width: 6,
                        currentWidth: 6,
                    },
                ],
                key: 'columns',
                type: 'columns',
                input: false,
                tableView: false,
            },
        ],
    };

    public constructor(private accessFacade: AccessFacadeService) {}

    public async ngOnInit() {}

    public onDataChange($event: any) {
        console.log($event);
    }
}
