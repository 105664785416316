<mat-list *ngIf="labResult">
    <!-- Date & time -->
    <mat-list-item class="text-center font-semibold">
        <span matListItemTitle>
            {{ labResult.observation_date_time | date: 'dd.MM.yy, HH:mm' }}
        </span>
    </mat-list-item>

    <!-- Group list -->
    <ng-container *ngFor="let group of labResult.groups">
        <!-- Group header (separator) -->
        <mat-list-item
            class="lab-result-viewer-group-header-item"
            [ngStyle]="{
                background: 'var(--group-color-' + group.color + ', var(--color-background-primary-color, #eaddff))',
            }"
        >
            <span matListItemTitle> {{ getGroupLabel(group) }}</span>
        </mat-list-item>

        <!-- Value list for group -->
        <mat-list-item *ngFor="let value of group.values">
            <span matListItemTitle class="lab-result-viewer-value-header"> • {{ getValueTitle(value) }} </span>
            <!-- <span matListItemLine *ngIf="getValueUnitLabel(value) as text" class="lab-result-viewer-value-subheader">
                {{ text }}
            </span> -->
            <span matListItemLine *ngIf="value.comments" class="lab-result-viewer-value-subheader">
                {{ value.comments }}
            </span>

            <span matListItemTitle class="lab-result-viewer-item-value">
                <div>{{ value.value | stringReplace: '.' : ',' }}</div>
                <p matListItemLine class="lab-result-viewer-value-subheader">{{ getValueUnitLabel(value) }}</p>
            </span>
        </mat-list-item>
    </ng-container>
</mat-list>
