import { Injectable, OnDestroy } from '@angular/core';
import { NgEventBus } from 'ng-event-bus';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { DeviceFacadeService } from '../facades/device-facade.service';
import { Device } from '../../models/auth.model';
import { ServerSentEventService } from './server-sent-event.service';
import { LocatorsFacadeService } from '../facades/locators-facade.service';
import { Locator } from '../../models/view-content.model';
import { DataRepositoryService } from '../datarepository.service';
import { Cp2ApiService } from '../cp2-api.service';
import { ViewContentFacadeService } from '../facades/viewcontent-facade.service';
import * as jose from 'jose';
import { decodeJwt, JWTPayload } from 'jose';
import { v4 as uuidv4 } from 'uuid';
import { AccessFacadeService } from '../facades/access-facade.service';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';

/**
 * Service for handling Server-Sent Events (SSE) and managing device-related events.
 */
@Injectable({
    providedIn: 'root',
})
export class ServerSentEventHandlerService implements OnDestroy {
    /** Current access token for API requests */
    private currentAccessToken: string | null = null;
    /** Current device information */
    private currentDevice: Device | null = null;
    /** Set of active topics to prevent duplicate subscriptions */
    private activeTopics = new Set<string>();
    /** Array to store all subscriptions for easy cleanup */
    private allSubs: Subscription[] = [];

    private isSubscribed: boolean = false; // Add a flag to track subscriptions

    private processedCount: number = 0;

    private locatorLength: number = 0;

    private locatorStatusSubject = new BehaviorSubject<{
        remaining: number;
        processed: number;
        locatorLog: { locator: string; success: boolean }[];
    }>({
        remaining: 0,
        processed: this.processedCount,
        locatorLog: [], // Initialisiere als leeres Array
    });

    // Observable für den Fortschritt
    public locatorStatus$: Observable<{
        remaining: number;
        processed: number;
        locatorLog: { locator: string; success: boolean }[];
    }> = this.locatorStatusSubject.asObservable();

    constructor(
        private readonly apiService: Cp2ApiService,
        private readonly eventBus: NgEventBus,
        private readonly deviceFacade: DeviceFacadeService,
        private readonly sseService: ServerSentEventService,
        private readonly locatorsFacade: LocatorsFacadeService,
        private readonly viewContentFacade: ViewContentFacadeService,
        private readonly dataRepository: DataRepositoryService,
        private accessFacade: AccessFacadeService
    ) {}

    public initializeMercureTopicSubscriptions(accessToken: string): void {
        if (this.isSubscribed) {
            console.warn('Already subscribed. Skipping initialization.');
            return; // If already subscribed, skip reinitializing
        }

        if (!this.isValidToken(accessToken)) {
            console.error('Invalid access token provided.');
            return;
        }

        this.currentAccessToken = accessToken;
        this.isSubscribed = true; // Mark as subscribed to prevent duplicate calls

        // Subscribe to device changes
        this.allSubs.push(
            this.deviceFacade.device$.subscribe({
                next: (deviceState) => this.handleDeviceChange(deviceState),
                error: (error) => this.handleError(error, 'deviceSubscription'),
            })
        );

        // Subscribe to device ID changes
        this.allSubs.push(
            this.deviceFacade.getDeviceId().subscribe({
                next: (deviceId: string) => {
                    this.handleDeviceIdChange(deviceId);
                    if (this.currentAccessToken && deviceId) {
                        this.proceedLocatorQueue(deviceId);
                    }
                },
                error: (error) => this.handleError(error, 'deviceIdSubscription'),
            })
        );

        // Subscribe to periodic ping events
        this.subscribeToServerSentEvents(['periodicPing'], (eventData: any) => {
            this.eventBus.cast('sse:periodicPing', eventData);
        });
    }

    /**
     * Cleans up resources when the service is destroyed.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((sub) => sub.unsubscribe());
        this.activeTopics.clear();
        this.clearSensitiveData();
    }

    public async proceedLocatorQueue(deviceId: string): Promise<void> {
        // Fetch the locators from the repository
        let locators: Locator[] = await this.dataRepository.getLocators();
        this.locatorLength = locators.length;

        this.updateLocatorStatus(this.locatorLength, 0, {} as any);

        for (const locator of locators) {
            try {
                const resp = await this.apiService.getVcForLocator(locator.locator, this.currentAccessToken!, deviceId);

                if (resp) {
                    if (this.currentAccessToken) {
                        const decodedToken = jose.decodeJwt(this.currentAccessToken);
                        const t = await firstValueFrom(this.accessFacade.userTokens$);
                        const user: CP2_User = {
                            id: t.token?.related_user_id ?? -1,
                            uuid: decodedToken['sub'] ?? '',
                            surname: decodedToken['family_name'] as string,
                            name: decodedToken['given_name'] as string,
                        };

                        this.viewContentFacade.saveViewContent(resp, user);
                        locators = locators.filter((loc) => {
                            return loc.id !== locator.id;
                        });

                        this.locatorLength = locators.length;

                        this.processedCount++;

                        this.updateLocatorStatus(this.locatorLength, this.processedCount, {
                            locator: locator.locator,
                            success: true,
                        });

                        this.locatorsFacade.saveLocators(locators);
                    }
                } else {
                    this.updateLocatorStatus(this.locatorLength, this.processedCount, {
                        locator: locator.locator,
                        success: false,
                    });
                }
            } catch (error) {
                console.error(`Error processing locator with ID: ${locator.id}`, error);
            }
        }
    }

    public updateLocatorStatus(
        remaining: number,
        processed: number,
        locatorLog: {
            locator: string;
            success: boolean;
        },
        resetStatus: boolean = false
    ): void {
        let currentStatus: any;
        let updatedLog: any;

        if (!resetStatus) {
            // Hole den aktuellen Status
            currentStatus = this.locatorStatusSubject.getValue();

            // Füge das neue Log zum bestehenden Log-Array hinzu
            updatedLog = [...currentStatus.locatorLog, locatorLog];
        } else {
            // Füge das neue Log zum bestehenden Log-Array hinzu
            updatedLog = [];
        }
        // Aktualisiere den Status mit dem neuen Log-Array
        this.locatorStatusSubject.next({
            remaining,
            processed,
            locatorLog: updatedLog, // Setze das aktualisierte Log-Array
        });
    }

    private async getViewContentByLocator(event: any) {
        const eventBusKey: string = event.key.split('/')[0];
        const deviceId: string = event.key.split('/')[1];

        if (eventBusKey === 'sse:device' && this.currentAccessToken) {
            const resp = await this.apiService.getVcForLocator(event.data, this.currentAccessToken, deviceId, {
                send_history: 'true',
            });

            if (resp === null) {
                const newLocator: Locator = {
                    id: event.id,
                    datetime: new Date(event.timestamp).toISOString(),
                    locator: event.data,
                };
                const allLocators: Locator[] = await this.dataRepository.getLocators();
                const updatedLocators: Locator[] = [...allLocators, newLocator];
                await this.dataRepository.saveLocators(updatedLocators);

                this.locatorLength = updatedLocators.length;
                this.updateLocatorStatus(this.locatorLength, this.processedCount, {
                    locator: newLocator.locator,
                    success: false,
                });
            } else {
                const decodedToken = jose.decodeJwt(this.currentAccessToken);
                const t = await firstValueFrom(this.accessFacade.userTokens$);
                const user: CP2_User = {
                    id: t.token?.related_user_id ?? -1,
                    uuid: decodedToken['sub'] ?? '',
                    surname: decodedToken['family_name'] as string,
                    name: decodedToken['given_name'] as string,
                };

                this.processedCount++;

                this.updateLocatorStatus(this.locatorLength, this.processedCount, {
                    locator: event.data,
                    success: true,
                });

                this.viewContentFacade.saveViewContent(resp, user);
            }
        }
    }

    /**
     * Handles device state changes.
     * @param deviceState - The current device state.
     */
    private handleDeviceChange(deviceState: { device: Device | null }): void {
        const deviceId = deviceState.device?.deviceId;
        if (deviceId && !this.activeTopics.has(`device/${deviceId}`)) {
            this.currentDevice = { ...this.currentDevice, deviceId };
            this.subscribeToServerSentEvents([`device/${deviceId}`], (eventData: any) => {
                this.eventBus.cast(`sse:device/${deviceId}`, eventData);
            });
        }
    }

    /**
     * Handles device ID changes.
     * @param deviceId - The new device ID.
     */
    private handleDeviceIdChange(deviceId: string): void {
        if (deviceId && !this.activeTopics.has(`sse:device/${deviceId}`)) {
            this.allSubs.push(
                this.eventBus.on(`sse:device/${deviceId}`).subscribe({
                    // next: (event) => this.processViewContentEvent(event),
                    next: (event) => this.getViewContentByLocator(event),
                    error: (error) => this.handleError(error, 'eventBusSubscription'),
                })
            );
        }
    }

    /**
     * Processes view content events using locators as a queue.
     * @param event - The event data to process.
     */

    /*private async processViewContentEvent(event: any): Promise<void> {
        if (!this.currentAccessToken) {
            console.warn('No access token available. Skipping event processing.');
            return;
        }

        try {
            // Extracting event key information
            const eventBusKey: string = event.key.split('/')[0];
            const deviceId: string = event.key.split('/')[1];
            let isCaseListItem: boolean = false;
            let caseListItemUUID: string | undefined = undefined;
            let caseList: string | undefined = undefined;

            // Handle case.list.item event
            if (eventBusKey === 'sse:device') {
                if (event.data.includes('case.list.item.')) {
                    caseListItemUUID = event.data.split('.')[3];
                    caseList = `case.list.${caseListItemUUID}`;
                    isCaseListItem = true;
                }
            }

            // Create locator and add it to the queue
            const newLocator: Locator = this.createLocatorFromEvent(event);
            let allLocators = await this.dataRepository.getLocators();

            // Add new locator to the queue
            let updatedLocators = [...allLocators, newLocator];
            this.locatorsFacade.saveLocators(updatedLocators);

            // Process only one locator at a time
            if (!this.viewContentProcessing && updatedLocators.length > 0) {
                this.viewContentProcessing = true;

                const oldestLocator = this.findOldestLocator(updatedLocators);

                try {
                    console.log(`Processing locator: ${oldestLocator.locator}`);

                    // Attempt to fetch view content for the locator
                    const resp = await this.apiService.getViewContentByLocator(
                        oldestLocator.locator,
                        this.currentAccessToken,
                        deviceId
                    );

                    if (resp.status !== 400) {
                        // Success: Process view content
                        const viewContent = resp.data;

                        let user: CP2_User | undefined;
                        this.accessFacade.userTokens$.subscribe(async (t) => {
                            if (t.token) {
                                const decoded = jose.decodeJwt(t.token.access_token);
                                user = {
                                    userId: decoded['sub'] ?? '',
                                    surname: decoded['family_name'] as string,
                                    name: decoded['given_name'] as string
                                };
                                // Save the view content along with user info
                                this.viewContentFacade.saveViewContent(viewContent, user);
                            }
                        });

                        // Remove successfully processed locator from the queue
                        updatedLocators = updatedLocators.filter(loc => loc.id !== oldestLocator.id);
                        this.locatorsFacade.saveLocators(updatedLocators);

                    } else {
                        // If the response is 400, log a warning but don't delete the locator
                        console.warn(`Locator ${oldestLocator.locator} returned 400, will not be retried.`);
                    }

                } catch (error) {
                    // Handle errors (network or API failures)
                    console.error(`Error processing locator ${oldestLocator.locator}:`, error);

                } finally {
                    // Always stop processing flag
                    this.viewContentProcessing = false;
                }
            }

        } catch (error) {
            this.handleError(error, 'Error in processViewContentEvent');
        }
    }*/

    /**
     * Creates a Locator object from an event.
     * @param event - The event data.
     * @returns A new Locator object.
     * @example
     * const locator = this.createLocatorFromEvent({
     *   id: '123',
     *   timestamp: '2023-08-28T12:00:00Z',
     *   data: 'case.overview.[ID]'
     * });
     */
    private createLocatorFromEvent(event: any): Locator {
        return {
            id: event.id ?? uuidv4(),
            datetime: new Date(event.timestamp).toISOString(),
            locator: event.data as string,
        };
    }

    /**
     * Finds the oldest locator in a list.
     * @param locators - Array of Locator objects.
     * @returns The oldest Locator object.
     */
    private findOldestLocator(locators: Locator[]): Locator {
        return locators.reduce((oldest, current) =>
            new Date(current.datetime) < new Date(oldest.datetime) ? current : oldest
        );
    }

    /**
     * Subscribes to server-sent events for given topics.
     * @param topics - Array of topics to subscribe to.
     * @param onNext - Callback function for handling incoming events.
     */
    private subscribeToServerSentEvents(topics: string[], onNext: (eventData: any) => void): void {
        topics.forEach((topic) => {
            if (!this.activeTopics.has(topic)) {
                this.activeTopics.add(topic);
                this.allSubs.push(
                    this.sseService.getServerSentEvents(topic).subscribe({
                        next: onNext,
                        error: (error) => this.handleError(error, `${topic} event`),
                    })
                );
            }
        });
    }

    /**
     * Handles errors that occur in the service.
     * @param error - The error object.
     * @param context - The context in which the error occurred.
     */
    private handleError(error: any, context: string): void {
        console.error(`Error in ${context}:`, error);
    }

    /**
     * Clears sensitive data from the service.
     */
    private clearSensitiveData(): void {
        this.currentAccessToken = null;
        this.currentDevice = null;
    }

    /**
     * Validates a JWT token.
     * @param token - The token to validate.
     * @returns True if the token is valid, false otherwise.
     * @example
     * const isValid = this.isValidToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...');
     */
    private isValidToken(token: string): boolean {
        if (!token) return false;

        const parts = token.split('.');
        if (parts.length !== 3) return false;

        try {
            const decoded = decodeJwt(token) as JWTPayload;
            if (decoded.exp && Date.now() >= decoded.exp * 1000) return false;
        } catch (error) {
            return false;
        }

        return true;
    }
}
