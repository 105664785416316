import { FormioModule } from '@formio/angular';
import { Component, Inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule, JsonPipe } from '@angular/common';
import {
    FormioRendererComponent,
    FormioRendererData,
    FormioRendererForm,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { CalAgePipe } from '../../pipes/cal-age.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { Consultation } from '../../models/view-content.models/view-content-clinic-domain.model';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { PatientInfoDetailsComponent } from '../../components/patient-info/patient-info-details/patient-info-details.component';
import { Patient_Details, VcHistoryElement } from '../../models/view-content.models/view-content.model';
import { ToolboxService } from '../../services/toolbox.service';

export interface FormModalResult {
    role: 'cancel' | 'save';
    data: any;
}

export interface ExtendedConsulation extends Consultation {
    goal_date: 'yesterday' | 'today' | 'tomorrow' | 'on';
}

@Component({
    selector: 'app-form-modal',
    standalone: true,
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
    imports: [
        FormioModule,
        MatCardModule,
        JsonPipe,
        FormioRendererComponent,
        CommonModule,
        CalAgePipe,
        FormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        ChangingHistoryComponent,
        ScrollingModule,
        MatBadgeModule,
        MatOption,
        MatSelect,
        MatInput,
        PatientInfoDetailsComponent,
    ],
})
export class FormModalComponent<T> implements OnInit {
    private static readonly TAG = 'FormModalComponent';
    /**the data for form self, the json files*/
    public form: any;
    /**the data in the form */
    public formData: any;
    public renderOptions: any = {};
    /**the name of the form */
    public form_file_name: string = '';
    /**the title of the dialog */
    public formTitle: string = '';
    /**translation data for the formular */
    public i18n: FormioRendererI18n | undefined;
    /**translation for the key of the incoming data
     * this variable also work as a flag to control when to show the whole container of changing history
     */
    public formioRendererData: FormioRendererData[] = [];
    public patientInfo: Patient_Details;
    public showHistory: boolean = false;
    public histories: VcHistoryElement<any>[] = [];

    public constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            // Form Attributes
            form_data?: any;
            form_file_name?: string;
            form_title?: string;
            formioForm?: FormioRendererForm;

            // History and Related Attributes
            history: any[];
            historyI18n: FormioRendererI18n | undefined;
            hideHistoryFields: string[];

            // Patient Details
            vcPatientDetails: Patient_Details;

            // Display/Behavior Settings
            hideCurrentPlace: boolean;
        },
        private tb: ToolboxService,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.formData = data.form_data;
        this.patientInfo = data.vcPatientDetails;
        this.histories = data.history || [];
        this.histories.sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime());

        if (data.form_file_name) {
            this.form_file_name = data.form_file_name;
            this.loadJSON().then(() => {
                if (data.form_file_name) {
                    this.formTitle = data.form_title || this.getFormName(data.form_file_name);
                }
            });
        }

        if (data.formioForm) {
            this.form = data.formioForm;
            this.formTitle = data.form_title || '';
        }
    }

    public async ngOnInit(): Promise<void> {
        if (!this.data.formioForm) await this.loadJSON();
        if (this.data.form_data) this.buildFormioRendererData();
        this.setLanguage();
    }

    public onClickOnSave(): void {
        this.dialogRef.close({ role: 'save', data: this.formioRendererData });
    }

    public onClickOnCancel(): void {
        this.dialogRef.close({ role: 'cancel', data: [] });
    }

    private buildFormioRendererData(): void {
        if (!this.data.form_data) return;
        this.formioRendererData = Object.entries(this.data.form_data.data).map(([key, value]) => ({
            key,
            value: value as string | number | boolean | undefined,
        }));
    }

    private async loadJSON(): Promise<void> {
        const content = await (await fetch('assets/forms/' + this.data.form_file_name)).json();
        this.form = content.form;
        this.renderOptions.i18n = content.i18n;
        this.i18n =
            content.i18n && this.data.historyI18n
                ? this.tb.mergeI18n(content.i18n, this.data.historyI18n)
                : (content.i18n ?? this.data.historyI18n);
    }

    private setLanguage(lang?: string) {
        this.renderOptions.language = lang || this.translate.currentLang;
    }

    private getFormName(formFilename: string): string {
        const res = formFilename.split('_')[1].split('.')[0];
        const currentLang = this.renderOptions.language || this.translate.currentLang || 'de';
        return this.i18n?.[currentLang]?.[res] || res;
    }
}
