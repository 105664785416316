import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import dayjs from 'dayjs';
import { LoginCallbackComponent } from '../../utility/login-callback/login-callback.component';
import { bloodPressureValidator } from './bloodPressureValidator';
import { MatIconModule } from '@angular/material/icon';
import { ChangingHistoryComponent } from '../../data-interaction/changing-history/changing-history.component';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';

export interface VitalDialogData {
    index: number;
    temperatur?: number;
    heartRate?: number;
    respiratoryRate?: number;
    bloodPressureSystolic?: number;
    bloodPressureDiastolic?: number;
    documentationDate: string;
    timeStamp: string;
    fieldsToShow?: VitalDialogFieldsToShow;
    showPlusButton?: boolean;
    role?: string;
    history: any[];
}

export interface VitalDialogFieldsToShow {
    temperature: boolean;
    heartRate: boolean;
    respiratoryRate: boolean;
    bloodPressure: boolean;
}

@Component({
    selector: 'app-vital-values',
    templateUrl: './vital-values.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DatePipe,
        FormsModule,
        LoginCallbackComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        ChangingHistoryComponent,
        MatBadgeModule,
        TranslateModule,
    ],
    styleUrls: ['./vital-values.component.scss'],
})
export class VitalValuesComponent {
    public vitalSignsForm: FormGroup;

    public fieldsToShow: VitalDialogFieldsToShow = {
        temperature: true,
        heartRate: true,
        respiratoryRate: true,
        bloodPressure: true,
    };

    public showPlusButton = false;
    public showHistory = false;
    public history: any[] = [];
    public readonly historyI18n = {
        de: {
            rows_0_data_0_value: 'Wert',
            rows_0_data_0_date: 'Datum',
            rows_0_label: 'Label',
            rows_0_sortOrder: 'Sortierungsindex',
        },
        en: {
            rows_0_data_0_value: 'Value',
            rows_0_data_0_date: 'Date',
            rows_0_label: 'Label',
            rows_0_sortOrder: 'Sort order',
        },
    };
    public hideHistoryFields = [
        'position',
        'status',
        'Status',
        'Sortierungsindex',
        'sortOrder',
        'rows_0_status',
        'label',
        'Label',
        'rows_0_label',
    ];

    public constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<VitalValuesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VitalDialogData
    ) {
        this.vitalSignsForm = this.fb.group(
            {
                index: [data.index],
                temperatur: [
                    data.temperatur,
                    // [Validators.min(35), Validators.max(41.5)], // Validators deactivate by RL 2024-09-06
                ],
                heartRate: [
                    data.heartRate,
                    // [Validators.min(40), Validators.max(180)], // Validators deactivate by RL 2024-09-06
                ],
                respiratoryRate: [
                    data.respiratoryRate,
                    // [Validators.min(0), Validators.max(40)], // Validators deactivate by RL 2024-09-06
                ],
                bloodPressureSystolic: [
                    data.bloodPressureSystolic,
                    // [Validators.min(0), Validators.max(310)], // Validators deactivate by RL 2024-09-06
                ],
                bloodPressureDiastolic: [
                    data.bloodPressureDiastolic,
                    // [Validators.min(0), Validators.max(310)], // Validators deactivate by RL 2024-09-06
                ],
                documentationDate: [
                    {
                        value: dayjs().format('YYYY-MM-DDTHH:mm'),
                        disabled: true,
                    },
                ],
                timeStamp: [dayjs(data.timeStamp).format('YYYY-MM-DDTHH:mm'), Validators.required],
            },
            { validators: bloodPressureValidator() }
        );

        if (data.fieldsToShow) this.fieldsToShow = data.fieldsToShow;
        if (data.showPlusButton != null) this.showPlusButton = data.showPlusButton;
    }

    //#region Listeners
    public onSubmit(): void {
        if (this.vitalSignsForm.valid) {
            const res = { ...this.vitalSignsForm.value, role: 'save' };
            this.dialogRef.close(res);
        }
    }

    public onCancel(): void {
        this.dialogRef.close({ role: 'cancel' });
    }

    public async onClickOnAddButton(): Promise<void> {
        this.dialogRef.close({ role: 'add' });
    }
    //#endregion
}
