import {
    FormioRendererForm,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { CommunicationMessage } from '../communication.model';
import { CurveGroup } from '../curve.model';
import {
    Consultation,
    Diagnose,
    Diagnostic,
    Discharge,
    LabReport,
    Medical_History as Medical_History,
    Pathogen,
    Post_Treatment,
    Task,
    Therapy,
    Visit_Record,
} from './view-content-clinic-domain.model';
import { Place } from './view-content-organization-domain.model';
import { Case, Patient } from './view-content-patient-domain.model';
import { CP2_User } from './view-content-personal-domain.model';
import { Finding } from '../patient-records.model';

export interface Traceable {
    modifiedAt: string;
    modifiedBy: CP2_User;
}

export interface VcHistoryElement<T> extends Traceable {
    data: T;
}

export interface ViewContent<T> {
    id: number;
    locator: string;
    owners: string[];
    owner_departments: string[];
    main_owner_job_type: 'doctor' | 'nurse' | 'admin' | 'other';
    created_at: string;
    updated_at?: string;
    status: 'final' | 'not_final' | 'archived';
    related_patient_id: string;
    related_case_id: string;
    data: T;
    form?: FormioRendererForm;
    i18n?: FormioRendererI18n;
    history?: VcHistoryElement<T>[];
}

export const getNewViewContent = <T>({
    locator,
    related_patient_id,
    related_case_id,
    data,
    id = -1,
    owners = [],
    owner_departments = [],
    main_owner_job_type = 'other',
    created_at = new Date().toISOString(),
    status = 'final',
    form,
    i18n,
}: {
    locator: string;
    related_patient_id: string;
    related_case_id: string;
    data: T;
    id?: number;
    owners?: string[];
    owner_departments?: string[];
    main_owner_job_type?: 'doctor' | 'nurse' | 'admin' | 'other';
    created_at?: string;
    status?: 'final' | 'not_final';
    form?: FormioRendererForm;
    i18n?: FormioRendererI18n;
}): ViewContent<T> => ({
    id,
    locator,
    owners,
    owner_departments,
    main_owner_job_type,
    created_at,
    status,
    related_patient_id,
    related_case_id,
    data,
    form,
    i18n,
});

export interface Patient_Details {
    case: ViewContent<Case>;
    patient: ViewContent<Patient>;
    current_place: ViewContent<Place>;
    last_diagnosis?: ViewContent<Diagnose>;
}

export interface VC_Case extends ViewContent<Case> {}

export interface VC_Medical_History extends ViewContent<Medical_History> {}

export interface VC_Diagnose extends ViewContent<Diagnose> {}

export interface VC_Therapy extends ViewContent<Therapy> {}

export interface VC_Visit_Record extends ViewContent<Visit_Record> {}

export interface VC_Diagnostic extends ViewContent<Diagnostic> {}

export interface VC_Consultation extends ViewContent<Consultation> {}

export interface VC_Task extends ViewContent<Task> {}

export interface VC_Discharge extends ViewContent<Discharge> {}

export interface VC_Post_Treatment extends ViewContent<Post_Treatment> {}

export interface VC_Pathogen extends ViewContent<Pathogen> {}

export interface VC_Findings extends ViewContent<Finding> {}

export interface Case_Overview {
    case: VC_Case;
    medical_history: VC_Medical_History;
    diagnose: VC_Diagnose;
    therapy: VC_Therapy[];
    visit_record: VC_Visit_Record;
    diagnostic: VC_Diagnostic[];
    consultation: VC_Consultation[];
    task: VC_Task[];
    discharge: VC_Discharge[];
    pathogen: VC_Pathogen[];
    post_treatment: VC_Post_Treatment[];
}

export interface VcPatientListItem {
    patient_details: Patient_Details;
    visit_record?: VC_Visit_Record;
    tasks: VC_Task[];
}

/** In few words, a PatientList is an array of PatientListGroups */
export interface PatientList extends Array<PatientListGroup> {}

export interface PatientListIdentifier {
    id: number; // Local id, not API id
    name: string;
    list_type: string;
}

export interface PatientListGroup {
    listIdentifier: PatientListIdentifier;
    patients: VcPatientListItem[];
}

export interface VC_CurveGroup extends ViewContent<CurveGroup> {}

export interface VC_LabReport extends ViewContent<LabReport> {}

export interface VC_CommunicationMessage extends ViewContent<CommunicationMessage> {}
