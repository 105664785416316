<div class="vital-values-modal-header">
    <button
        mat-icon-button
        aria-label="Neuer Eintrag"
        *ngIf="showPlusButton"
        (click)="onClickOnAddButton()"
        matTooltip="Neuen Eintrag eingeben"
    >
        <mat-icon>add</mat-icon>
    </button>

    <button mat-mini-fab (click)="showHistory = !showHistory">
        <mat-icon>track_changes</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form
        id="vital-form"
        class="vital-form-dialog w-full flex flex-col"
        [formGroup]="vitalSignsForm"
        (ngSubmit)="onSubmit()"
    >
        <h5>Vitalwerte eintragen</h5>

        <!-- Heart rate -->
        <mat-form-field *ngIf="fieldsToShow.heartRate" class="form-item w-full" appearance="outline">
            <mat-label>Puls (¹/min)</mat-label>
            <input matInput id="heartRate" formControlName="heartRate" type="number" />
        </mat-form-field>

        <!-- Blood pressure -->
        <mat-form-field *ngIf="fieldsToShow.bloodPressure"
        [ngClass]="{'vital-values-invalid-field': vitalSignsForm.hasError('invalidBloodPressure')}">
            <mat-label>Blutdruck (mmHg)</mat-label>
            <div class="bloodpressure">
                <input matInput id="bloodPressureSystolic" formControlName="bloodPressureSystolic" type="number" />
                <span class="separator">/</span>
                <input matInput id="bloodPressureDiastolic" formControlName="bloodPressureDiastolic" type="number" />
            </div>
        </mat-form-field>
        <div *ngIf="vitalSignsForm.hasError('invalidBloodPressure')" style="color: var(--danger-color);">
            {{ 'COMPONENT.PATIENT_DETAILS.CURVE.VITAL_SIGNS_DIALOG.error_msg_systolic_greater_diastolic' | translate }}
        </div>

        <!-- Breathing -->
        <mat-form-field *ngIf="fieldsToShow.respiratoryRate" class="form-item w-full" appearance="outline">
            <mat-label>Atemfrequenz (¹/min)</mat-label>
            <input matInput id="respiratoryRate" formControlName="respiratoryRate" type="number" />
        </mat-form-field>

        <!-- Temperature -->
        <mat-form-field *ngIf="fieldsToShow.temperature" class="form-item w-full" appearance="outline">
            <mat-label>Körpertemperatur (°C)</mat-label>
            <input matInput id="temperatur" formControlName="temperatur" type="number" step="0.1" />
        </mat-form-field>

        <!-- Measure timestamp -->
        <mat-form-field class="form-item w-full" appearance="outline">
            <mat-label>Messzeitpunkt</mat-label>
            <input matInput id="timeStamp" formControlName="timeStamp" type="datetime-local" />
        </mat-form-field>

        <!-- Input timestamp -->
        <mat-form-field class="form-item w-full" appearance="outline">
            <mat-label>Dokumentationsdatum</mat-label>
            <input matInput id="documentationDate" formControlName="documentationDate" type="datetime-local" />
        </mat-form-field>
    </form>

    <!-- History -->
    <div *ngIf="showHistory" class="change-history-container">
        <div [matBadge]="data.history.length" matBadgeOverlap="false" class="w-fit h6">
            {{ 'COMPONENT.DIALOG_PATIENT_OVERVIEW.changing_history' | translate }}
        </div>

        @for (history of data.history; track data.history; let i = $index; let last = $last) {
            <app-changing-history
                [index]="i"
                [data]="data.history.slice(i, i + 2)"
                [last]="last"
                [showOldValues]="false"
                [i18n]="historyI18n"
                [hideFields]="hideHistoryFields"
            ></app-changing-history>
        }
    </div>
</mat-dialog-content>

<mat-dialog-actions class="flex-end">
    <!-- Cancel button -->
    <button type="button" mat-stroked-button (click)="onCancel()" color="neutral">Abbrechen</button>

    <!-- Save button -->
    <button type="submit" [disabled]="!vitalSignsForm.valid" mat-flat-button color="primary" form="vital-form">Speichern</button>
</mat-dialog-actions>
