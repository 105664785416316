import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { MatExpansionModule } from '@angular/material/expansion';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MatIconModule } from '@angular/material/icon';
import { DialogPatientOverviewComponent } from '../../../modals/dialog-patient-overview/dialog-patient-overview.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicListComponent } from '../../data-interaction/dynamic-list/dynamic-list.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import { Case_Overview, Patient_Details } from '../../../models/view-content.models/view-content.model';

dayjs.extend(customParseFormat);

export interface DialogData {
    dateAndTime: string;
    catalog: string;
    content: string;
}

@Component({
    selector: 'app-patient-overview',
    templateUrl: './patient-overview.component.html',
    styleUrls: ['./patient-overview.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        DialogPatientOverviewComponent,
        DynamicListComponent,
        MatButtonModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatListModule,
        TranslateModule,
    ],
})
export class PatientOverviewComponent implements OnInit {
    @Input() patientDetailsI18n: FormioRendererI18n | undefined;
    @Input() vcPatientDetails: Patient_Details | undefined;
    //incoming data, contains related patient id and coresponding data, such as medical history,therapy
    @Input() public caseOverview: Case_Overview | undefined;

    @Output() public changedOverviewData: EventEmitter<any> = new EventEmitter();

    public differenceInDays: number | undefined | string;
    protected readonly Object = Object;

    public constructor(public dialog: MatDialog) {}

    public ngOnInit() {
        if (this.caseOverview?.consultation) {
            this.caseOverview.consultation = this.showGoalDateOn(this.caseOverview.consultation);
        }

        if (this.caseOverview?.therapy) {
            this.caseOverview.therapy = this.showGoalDateOn(this.caseOverview.therapy);
        }

        if (this.caseOverview?.task) {
            this.caseOverview.task = this.showGoalDateOn(this.caseOverview.task);
        }

        if (this.caseOverview?.diagnostic) {
            this.caseOverview.diagnostic = this.showGoalDateOn(this.caseOverview.diagnostic);
        }
    }

    //#region Listeners
    public async addNewVc(formFileName: string, type: string, vcPatientDetails?: Patient_Details): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                // Form Attributes
                form_file_name: formFileName,

                // Patient Details
                vcPatientDetails: vcPatientDetails,

                // Display/Behavior Settings
                hideCurrentPlace: true,
            },

            panelClass: 'patient-overview-dialog-container',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (res.role === 'save') {
            this.changedOverviewData.emit({
                type: type,
                method: 'ADD',
                data: res.data,
            });
        }
    }

    //#endregion

    /**for the data with only on entry, like anamnese,diagnose,
     * following the data property contains all the data, and in theory, the referenceIds are always the same.
     * for the multiple data like therapy, diagnostic, they can have more than one entries, and each entry have one id
     * the following property data only contains one entry, history contains all the data, the whole array.
     *
     */
    public async updateVc(
        data: any,
        formFileName: string,
        type: string,
        vc?: any,
        vcPatientDetails?: Patient_Details,
        viewContentI18n?: FormioRendererI18n,
        history?: any[]
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                // Form Attributes
                form_data: { data }, // Formular Data
                form_file_name: formFileName,

                // History and Related Attributes
                history,
                viewContentI18n,

                // Patient Details
                vcPatientDetails: vcPatientDetails,

                // Display/Behavior Settings
                hideCurrentPlace: true,
            },
            maxHeight: 'calc(100vh - 16px)',
            panelClass: 'patient-overview-dialog-container',
        });

        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res || res.role === 'cancel') return;

        this.changedOverviewData.emit({
            type: type,
            method: 'UPDATE',
            data: res.data,
            vc: vc,
        });
    }

    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    /** to show exactly goal date in the form  */
    /** to check if there is a goal date already in the data, if true, then add one more
     * attribute goal_date, to show the radio('today'|'yesterday'|'tomorrow'|'on') in the form */
    private showGoalDateOn(data: any[]): any[] {
        return data.map((el) => {
            let updatedEl = { ...el };

            if (el.data.goalDateOn) {
                updatedEl.data.goal_date = 'on';
            }

            if (el.data.dayCountDeactivateOn) {
                updatedEl.data.dayCountDeactivate = 'on';
            }

            return updatedEl;
        });
    }
}
