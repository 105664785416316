import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogClose,
    MatDialogRef,
    MatDialogConfig,
} from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PatientNameAndDob } from '../../pages/patient-details/patient-details.component';
import { FormModalComponent, FormModalResult } from '../form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
    FormioRendererData,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { MatBadgeModule } from '@angular/material/badge';
import { Visit_Record } from '../../models/view-content.models/view-content-clinic-domain.model';
import { VC_Visit_Record } from '../../models/view-content.models/view-content.model';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import dayjs from 'dayjs';
import { ToolboxService } from '../../services/toolbox.service';

const historyI18nEntries: FormioRendererI18n = {
    de: {
        note: 'Notiz',
        date_and_time: 'Zeitpunkt',
    },
    en: {
        note: 'Note',
        date_and_time: 'Date and time',
    },
};

@Component({
    selector: 'app-start-page-popup-list',
    templateUrl: './start-page-popup-list.component.html',
    styleUrls: ['./start-page-popup-list.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatCardModule,
        MatExpansionModule,
        CalAgePipe,
        DayAgoPipe,
        TranslateModule,
        CommonModule,
        MatListModule,
        MatIcon,
        MatButtonModule,
        MatDialogClose,
        ChangingHistoryComponent,
        MatBadgeModule,
    ],
})
export class StartPagePopupListComponent implements OnInit {
    public panelOpenState = false;
    public showHistory: boolean = false;
    public vcVisitRecord: VC_Visit_Record | undefined;
    public viewContentI18n: FormioRendererI18n;
    public formioRendererData: FormioRendererData[] = [];
    public currentBreakpoint: string = '';

    public constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: VC_Visit_Record;
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
            currentBreakpoint: string;
        },
        public dialog: MatDialog,
        public tb: ToolboxService
    ) {
        this.vcVisitRecord = data.data;
        this.viewContentI18n = data.viewContentI18n
            ? this.tb.mergeI18n(data.viewContentI18n, historyI18nEntries)
            : historyI18nEntries;
        this.currentBreakpoint = data.currentBreakpoint;
    }

    public ngOnInit(): void {
        if (this.vcVisitRecord?.history) {
            this.vcVisitRecord.history = this.processVisits(this.vcVisitRecord.history);
        } else {
            console.warn('vcVisitRecord or history is undefined');
        }
    }

    public onClose(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }

    /**open an exsiting visite formular */
    public async onClickOnElement(
        formFileName: string,
        patientInfo?: PatientNameAndDob,
        history?: {
            data: Visit_Record;
            modifiedAt: string;
            modifiedBy: CP2_User;
        }[]
    ): Promise<void> {
        let latestVisit = this.vcVisitRecord?.history?.[0] || {
            data: {
                date_and_time: '',
            },
        };
        latestVisit.data.date_and_time = this.getFormattedDate(latestVisit.data.date_and_time);

        const dialogConfig: MatDialogConfig = {
            data: {
                form_file_name: formFileName,
                form_data: latestVisit, //the data in history is sorted according to time, the first one is the latest
                patient_info: patientInfo,
                history,
                viewContentI18n: this.viewContentI18n,
            },
            panelClass: 'home-visite-dialog',
        };
        // Add logic for dialog position based on the currentBreakpoint
        if (this.currentBreakpoint === 'handsetPortrait' || this.currentBreakpoint === 'handsetLandscape') {
            dialogConfig.position = { bottom: '0' };
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    //according to the modified date, sort all the incoming data
    private processVisits(
        dataArray: {
            data: Visit_Record;
            modifiedAt: string;
            modifiedBy: CP2_User;
        }[]
    ): {
        data: Visit_Record;
        modifiedAt: string;
        modifiedBy: CP2_User;
    }[] {
        // Sort the entire array based on `modifiedAt` from latest to oldest
        return dataArray.sort((a, b) => {
            return new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime();
        });
    }
}
