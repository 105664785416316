export class MigrationUpgradeStatements {
    public upgradeMigrations = [
        {
            toVersion: 1,
            statements: [
                `CREATE TABLE IF NOT EXISTS view_content_data(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    data TEXT
                );`,

                `CREATE TABLE IF NOT EXISTS view_content_history(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    view_content_id INTEGER,
                    view_content_data_id INTEGER,
                    datetime TEXT,
                    editor TEXT,
                    FOREIGN KEY (view_content_id) REFERENCES view_content(id),
                    FOREIGN KEY (view_content_data_id) REFERENCES view_content_data(id)
                );`,

                `CREATE TABLE IF NOT EXISTS view_content(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    locator TEXT,
                    owners TEXT,
                    owner_departments TEXT,
                    main_owner_job_type TEXT,
                    created_at TEXT,
                    status TEXT,
                    related_patient_id TEXT,
                    related_case_id TEXT,
                    data_id INTEGER,
                    form TEXT,
                    i18n TEXT,
                    FOREIGN KEY (data_id) REFERENCES view_content_data(id)
                );
                `,
            ],
        },
        {
            toVersion: 2,
            statements: [
                `CREATE TABLE IF NOT EXISTS cp2_user(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    uuid TEXT UNIQUE,
                    surname TEXT,
                    name TEXT,
                    validSince TEXT,
                    validUntil TEXT
                );`,
            ],
        },
        {
            toVersion: 3,
            statements: [
                `CREATE TABLE IF NOT EXISTS vc_cache_pending_put(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    userJson TEXT,
                    vcLocator TEXT,
                    timestamp TEXT
                );`,
            ],
        },
        {
            toVersion: 5,
            statements: [
                `CREATE TABLE IF NOT EXISTS record_document_cache_pending_put(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    area TEXT,
                    subarea TEXT,
                    case_id TEXT,
                    documenttext TEXT,
                    filename TEXT,
                    timestamp TEXT
                );`,
            ],
        },
        {
            toVersion: 6,
            statements: [
                `CREATE TABLE IF NOT EXISTS case_list(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    name TEXT,
                    list_type TEXT,
                    UNIQUE (name, list_type)
                );`,

                `CREATE TABLE IF NOT EXISTS case_to_list(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    case_id TEXT,
                    list_id INTEGER,
                    FOREIGN KEY (list_id) REFERENCES case_list(id),
                    UNIQUE (case_id, list_id) ON CONFLICT REPLACE
                );`,
            ],
        },
        {
            toVersion: 7,
            statements: [
                `CREATE INDEX idx_case_to_list_case_id_list_id ON case_to_list(case_id, list_id);`,
            ],
        },
        {
            toVersion: 8,
            statements: [
                `CREATE INDEX idx_case_list_name_type ON case_list(name, list_type);`,

                `CREATE INDEX idx_view_content_history_view_content_id ON view_content_history(view_content_id);`,
                `CREATE INDEX idx_view_content_history_view_content_data_id ON view_content_history(view_content_data_id);`,

                `CREATE INDEX idx_view_content_locator ON view_content(locator);`,
                `CREATE INDEX idx_view_content_related_case_id ON view_content(related_case_id);`,

                `CREATE INDEX idx_cp2_user_uuid ON cp2_user(uuid);`,
            ],
        },
    ];
}
