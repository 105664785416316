<mat-card class="dialog-container">
    <div class="form-name-patient-info">
        <div>
            <div class="h5">
                {{ formTitle ?? '' }}
            </div>

            <!-- Patient details for patient overview page -->
            <app-patient-info-details [hideCurrentPlace]="data.hideCurrentPlace" [vcPatientDetails]="patientInfo"></app-patient-info-details>
            <!--<div class="patient-info p">
                <div *ngIf="patientInfo">
                    <strong>{{ patientInfo.lastName }}, {{ patientInfo.firstName }}</strong>,
                    {{ ("GENERAL." + patientInfo.gender) | translate }},
                    {{ patientInfo.dob | date: ("GENERAL.date_format" | translate) }}({{ patientInfo.dob | calAge }}),&nbsp;{{ data.patient_info.ward }},
                    {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
                    {{ data.patient_info.room }}{{ data.patient_info.bed }}
                </div>

                &lt;!&ndash; Patient details for orga and workflow &ndash;&gt;
                <div *ngIf="!patientInfo && formData?.data">
                    {{ formData?.data[0].lastName }}, {{ formData?.data[0].firstName }},
                    {{ ("GENERAL." + formData?.data[0].gender) | translate }},
                    {{ formData?.data[0].dob | date: ("GENERAL.date_format" | translate) }}
                    ({{ formData?.data[0].dob | calAge }}),&nbsp;{{ formData?.data[0].ward }},&nbsp;{{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}&nbsp;
                    {{ formData?.data[0].room.split(" ")[1] }}
                    {{ formData?.data[0].bed.split(" ")[1] }}
                </div>
            </div>-->
        </div>

        <div class="track-close-buttons flex">
            <!-- Show track changes button conditionally -->
            <button (click)="showHistory = !showHistory" mat-mini-fab>
                <mat-icon>track_changes</mat-icon>
            </button>
            <button (click)="onClickOnCancel()" mat-mini-fab>
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>

    <mat-card-content>
        <app-formio-renderer
            [(data)]="formioRendererData"
            [form]="form"
            [i18n]="i18n"
        ></app-formio-renderer>

        <!-- History -->
        <div *ngIf="showHistory" class="change-history-container">
            <div class="w-fit h6">
                {{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.changing_history" | translate }}
            </div>

            <ng-container *ngFor="let history of histories; let i = index; let last = last">
                <app-changing-history
                    [data]="histories.slice(i, i + 2)"
                    [form_file_name]="form_file_name"
                    [hideFields]="data.hideHistoryFields"
                    [i18n]="i18n"
                    [index]="i"
                    [last]="last"
                    [showOldValues]="false"
                ></app-changing-history>
            </ng-container>
        </div>
    </mat-card-content>

    <mat-card-actions class="justify-end">
        <button
            (click)="onClickOnCancel()"
            mat-stroked-button>
            {{ i18n?.[renderOptions.language]?.['Abbrechen'] ?? 'Abbrechen' }}
        </button>
        <button (click)="onClickOnSave()" mat-flat-button>
            {{ i18n?.[renderOptions.language]?.['Speichern'] ?? 'Speichern' }}
        </button>
    </mat-card-actions>
</mat-card>
