<div *ngIf="place" class="flex items-center cursor-default gap-1">
    <span (click)="onClickOnBreadcrumb('department', place.data.room.service_unit.department[0].code)" *ngIf="place?.data?.room?.service_unit?.department?.[0]?.code">
        <span class="breadcrumb">
            {{ place.data.room.service_unit.department[0].code }}
        </span>
    </span>
    <span (click)="onClickOnBreadcrumb('service_unit', place.data.room.service_unit.code)" *ngIf="place?.data?.room?.service_unit">
        <span>></span>
        <span class="breadcrumb">
            {{ place.data.room.service_unit.code }}
        </span>
    </span>
    <span (click)="onClickOnBreadcrumb('room', place.data.room.code)" *ngIf="place?.data?.room?.code">
        <span>></span>
        <span class="breadcrumb">
            {{ place.data.room.code }}
        </span>
    </span>
    <span *ngIf="place?.data?.code">>
        <span class="breadcrumb">
            {{ place.data.code }}
        </span>
    </span>
</div>
