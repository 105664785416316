<div [ngClass]="{ 'border-b-2': !last }" class="change-history-container">
    <div>
        <div class="h6">
            {{
                'COMPONENT.CHANGES_HISTORY.label_date_time_and_editor'
                    | translate
                        : {
                              date_and_time: modifiedAt | date: ('GENERAL.date_time_format' | translate),
                              editor: modifiedBy,
                          }
            }}
        </div>

        <table>
            <ng-container *ngFor="let diff of differences">
                <tr *ngIf="!hideFields?.includes(diff.key)">
                    <td class="align-top">
                        <strong>{{ diff.key }}: </strong>
                    </td>
                    <td>
                    <span
                        *ngIf="
                            showOldValues &&
                            diff.newValue != undefined &&
                            form_file_name !== 'form_anamnesis.json' &&
                            form_file_name !== 'form_diagnoses.json' &&
                            form_file_name !== 'form_visit.json'
                        "
                        class="table-text changed-content"
                    >
                        {{ diff.newValue }}
                    </span>
                        <span class="table-text history">{{ diff.oldValue }}</span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- Restore button -->
    <mat-icon
        (click)="openDialog()"
        *ngIf="index !== 0"
        class="restore-history-button hover:cursor-pointer"
        mat-mini-fab
    >
        settings_backup_restore
    </mat-icon>
</div>
