<div [style.height]="'calc(100vh - ' + appMenuClientHeight + 'px)'" class="p-2 flex flex-col gap-2">
    <!-- #region Station Selection -->
    <div class="flex flex-row w-full cursor-pointer items-center gap-1">
        <div
            (click)="handleSelectedStation(list)"
            *ngFor="let list of caseLists"
            [ngClass]="{
                '!bg-[var(--primary-color)]': list.listIdentifier.selected,
                '!text-white': list.listIdentifier.selected,
            }"
            class="p-2 bg-white flex-1 items-center text-center flex flex-row gap-1 justify-center text-nowrap shadow-sm hover:bg-[var(--color-background-primary-color-hover)]"
        >
            <mat-icon *ngIf="list.listIdentifier.selected">check</mat-icon>
            <div class="relative">
                <div>{{ list.listIdentifier.name }}</div>
            </div>
        </div>
        <!-- #endregion -->

        <!-- #region Sorting Area -->
        <mat-select
            [hideSingleSelectionIndicator]="true"
            [value]="sortOptions[0]"
            class="!w-32 bg-white p-2 !font-light shadow-sm"
        >
            <mat-option
                (click)="handleSorting(sort)"
                *ngFor="let sort of sortOptions"
                [value]="sort"
                class="!flex !items-center !justify-between !flex-row-reverse !gap-2 !font-light"
            >
                <span>{{ sort.value }}</span>
                <mat-icon *ngIf="sort.order === 'ASC' && sort.selected" class="!m-0">arrow_upward</mat-icon>
                <mat-icon *ngIf="sort.order === 'DESC' && sort.selected" class="!m-0">arrow_downward</mat-icon>
            </mat-option>
        </mat-select>
        <!-- #endregion -->

        <!-- #region Searching Area -->
        <div class="flex flex-row justify-between items-center bg-white p-2 shadow-sm">
            <input
                (input)="searchValue($event.target)"
                [placeholder]="'COMPONENT.PAGE_WORKFLOW.search_input_placeholder' | translate"
                class="font-light"
                matInput
            />
            <mat-icon>search</mat-icon>
        </div>
        <!-- #endregion -->
    </div>

    <!-- #region Filter Selection -->
    <div class="flex flex-row w-full items-center justify-evenly border rounded-3xl py-2">
        <div
            (click)="handleSelectedFilter(filter)"
            *ngFor="let filter of filterOptions"
            [ngClass]="{ '!bg-[var(--primary-color)]': filter.selected, '!text-white': filter.selected }"
            class="p-1 bg-white min-w-32 rounded-3xl items-center text-center flex flex-row justify-center font-light text-sm cursor-pointer shadow-sm hover:bg-[var(--color-background-primary-color-hover)]"
        >
            <mat-icon *ngIf="filter.selected" class="text-base">check</mat-icon>
            <div>{{ filter.value }}</div>
        </div>
    </div>
    <!-- #endregion -->

    <!-- #region Patient-Table -->
    <div class="flex-1 w-full overflow-auto max-h-full shadow">
        <table [dataSource]="dataSource" class="!bg-[var(--color-card-primary-color)]" mat-table>
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                <th *matHeaderCellDef class="!p-2" mat-header-cell>{{ column | translate }}</th>
                <td
                    *matCellDef="let element"
                    class="hover:!bg-[var(--color-background-primary-color-hover)] !p-0"
                    mat-cell
                >
                    <ng-container [ngSwitch]="column">
                        <!-- General Information -->
                        <div
                            *ngSwitchCase="translatedColumns['COMPONENT.PAGE_WORKFLOW.column_general_information']"
                            [routerLink]="['/patient-details', element?.patient_details.case.related_case_id]"
                            class="w-full h-full flex items-center !cursor-pointer"
                        >
                            <app-home-patient-basic-info
                                [disableDiagnosis]="true"
                                [patient]="element"
                                class="patient-list-basic w-full h-full !px-2"
                            ></app-home-patient-basic-info>
                        </div>

                        <!-- Task -->
                        <div
                            *ngSwitchCase="translatedColumns['COMPONENT.PAGE_WORKFLOW.column_task']"
                            class="w-full h-full flex items-center cursor-pointer"
                        >
                            <app-home-patient-tasks
                                [patient]="element"
                                class="w-full h-full !px-2"
                            ></app-home-patient-tasks>
                        </div>

                        <!-- Goal Date -->
                        <div
                            *ngSwitchCase="translatedColumns['COMPONENT.PAGE_WORKFLOW.column_edited_at']"
                            class="w-full h-full flex items-center cursor-default !px-2"
                        >
                            {{ element?.tasks?.[0]?.history?.[0]?.modifiedAt | date: 'short' }}
                        </div>

                        <!-- Editor -->
                        <div
                            *ngSwitchCase="translatedColumns['COMPONENT.PAGE_WORKFLOW.column_edited_by']"
                            class="w-full h-full flex items-center cursor-default !px-2"
                        >
                            {{ element?.tasks[0]?.history?.[0]?.modifiedBy.name }}
                            {{ element?.tasks[0]?.history?.[0]?.modifiedBy.surname }}
                        </div>

                        <!-- Status -->
                        <div
                            *ngSwitchCase="translatedColumns['COMPONENT.PAGE_WORKFLOW.column_status']"
                            [matTooltip]="
                                element?.tasks[0]?.data.done
                                    ? ('COMPONENT.PAGE_WORKFLOW.status_done' | translate)
                                    : ('COMPONENT.PAGE_WORKFLOW.status_not_done' | translate)
                            "
                            class="w-full h-full flex items-center cursor-default"
                        >
                            <div
                                [ngClass]="{
                                    'bg-[#2ecc71]': element?.tasks[0]?.data.done,
                                    'bg-[#c0392b]': !element?.tasks[0]?.data.done,
                                }"
                                class="h-5 w-5 rounded-full mx-auto"
                            ></div>
                        </div>
                    </ng-container>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" class="!bg-white text-nowrap" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns" class="text-nowrap" mat-row></tr>
        </table>
    </div>
    <!-- #endregion -->
</div>
