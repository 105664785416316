import { CommonModule, DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { firstValueFrom, Subscription } from 'rxjs';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {
    FormioRendererComponent,
    FormioRendererForm,
    FormioRendererI18n,
    FormioRendererData,
} from '../../data-interaction/formio-renderer/formio-renderer.component';
import { MediaToolbarComponent } from '../../media-toolbar/media-toolbar.component';
import { PdfViewerComponent } from '../../utility/pdf-viewer/pdf-viewer.component';
import { fadeInFromLeft, fadeInFromRight, fadeInFromTop } from '../../../shared/animations';
import { MatDialog } from '@angular/material/dialog';
import {
    Area,
    Finding,
    FindingsByRecords,
    PatientRecordVCData,
    Record,
    SubArea,
} from '../../../models/patient-records.model';
import { LabReport, LabResult } from '../../../models/view-content.models/view-content-clinic-domain.model';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LabTableViewerComponent } from '../lab-table-viewer/lab-table-viewer.component';
import { LabResultViewerComponent } from '../lab-result-viewer/lab-result-viewer.component';
import { DicomViewerComponent } from '../../dicom-viewer/dicom-viewer.component';
import {
    TextEditorBridgeComponent,
    TextEditorModalResult,
} from '../../utility/text-editor-bridge/text-editor-bridge.component';
import { ActivatedRoute } from '@angular/router';
import { AccessFacadeService } from '../../../services/facades/access-facade.service';
import * as jose from 'jose';
import { UserToken } from '../../../models/auth.model';
import { getNewViewContent, VC_Case, ViewContent } from '../../../models/view-content.models/view-content.model';
import { MatTooltip } from '@angular/material/tooltip';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { Cp2ApiService } from '../../../services/cp2-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { MetadataService } from '../../dicom-viewer/helper/metadata.service';
import dayjs from 'dayjs';
import { uuidv4 } from '@cornerstonejs/core/dist/cjs/utilities';
import { ViewContentCacheService } from '../../../services/cache/view-content-cache.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCardActions } from '@angular/material/card';

/**
 * Component for displaying patient files.
 */
@Component({
    selector: 'patient-files',
    templateUrl: './patient-files.component.html',
    styleUrls: ['./patient-files.component.scss'],
    standalone: true,
    animations: [fadeInFromRight, fadeInFromLeft, fadeInFromTop],
    imports: [
        CommonModule,
        DatePipe,
        DicomViewerComponent,
        FormioRendererComponent,
        FormsModule,
        LabResultViewerComponent,
        LabTableViewerComponent,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MediaToolbarComponent,
        PdfViewerComponent,
        MatTooltip,
        MatCardActions,
    ],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'shadow' },
        },
    ],
})
export class PatientFilesComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChild('laborViewerChip') laborViewerChip!: MatChipOption;

    // Inputs
    @Input() isFullscreenForImageEnabled!: { data: any; fullScreen: boolean };
    @Input() isPaintingToolOpened!: {
        data: any;
        isPaintingToolDialogOpened: boolean;
    };
    @Input() isMetaDataViewOpened = false;
    @Input() findings: Finding[] = [];
    @Input() areas: Area[] = [];
    @Input() subAreas: SubArea[] = [];
    @Input() records: Record[] = [];
    @Input() selectedRecord!: Record;
    @Input() vcFindings: ViewContent<PatientRecordVCData>[] = [];
    @Input() vcLabor: ViewContent<LabReport> = {} as ViewContent<LabReport>;
    @Input() patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        ward: '',
        room: '',
        bed: '',
    };

    @Output() clickOnFullScreen = new EventEmitter<{
        data: any;
        fullScreen: boolean;
    }>();
    @Output() isPaintingToolOpenedChanged = new EventEmitter<{
        data: any;
        isPaintingToolDialogOpened: boolean;
    }>();
    @Output() isMetaDataViewChanged = new EventEmitter<boolean>();
    @Output() newTextDocument = new EventEmitter<TextEditorModalResult['data']>();
    @Output() deletedFinding = new EventEmitter<ViewContent<PatientRecordVCData>>();

    public selectedFinding: Finding = {} as Finding;
    public selectedFindingBeforeLabViewer: Finding = {} as Finding;
    public findingsByRecords: FindingsByRecords<Finding> = {} as FindingsByRecords<Finding>;
    // public selectedArea: string = 'All';
    public selectedArea: Area | undefined;
    public isDropdownOpen = false;
    public isLabTableShowLimits = true;
    public shouldRenderFormioRenderer = true;
    public formioData: FormioRendererData[] = [];
    public viewMetaData: boolean = false;
    protected readonly Object = Object;
    private allSubs: Subscription[] = [];
    private token: UserToken | undefined;
    private caseDetails: VC_Case | undefined;
    private caseId?: string;

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        private dialog: MatDialog,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private route: ActivatedRoute,
        private vcCache: ViewContentCacheService,
        private snackBar: MatSnackBar,
        public metaDataService: MetadataService
    ) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.mjs';
        this.matIconRegistry.addSvgIcon(
            'clinical_notes',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clinical_notes.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'prescriptions',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/prescriptions.svg')
        );
    }

    public get allLabResults(): LabResult[] {
        if (this.vcLabor) {
            return this.vcLabor?.data.results;
        }

        return [];
    }

    async ngOnInit(): Promise<void> {
        this.allSubs.push(
            this.accessFacade.userTokens$.subscribe(async (t) => {
                if (!t?.token) return;

                const { access_token } = t.token;
                const decodedToken = jose.decodeJwt(access_token);

                this.token = t.token;
            })
        );

        // @todo: Remove this once the caseId is passed from the parent component or from view-content
        this.caseId = this.route.snapshot.paramMap.get('id') ?? undefined;

        if (!this.caseId) throw Error('Error refreshing view: no patientId found');
        if (!this.token) throw Error('Error refreshing view: no token available');

        this.caseDetails = await this.api.getVcCaseDetails(this.caseId, this.token.access_token);
    }

    /**
     * Lifecycle hook that is called when any data-bound property of a directive changes.
     * @param changes - SimpleChanges object that contains current and previous property values.
     */
    public ngOnChanges(changes: SimpleChanges) {
        this.shouldRenderFormioRenderer = false;
        setTimeout(async () => {
            const hasFindingsChanged = !!changes['vcFindings'];
            const hasLaborChanged = !!changes['vcLabor'];

            if (hasFindingsChanged) {
                this.vcFindings = this.vcFindings.length > 0 ? this.sortFindingsByLatest(this.vcFindings) : [];
            }

            if (hasLaborChanged) {
                this.vcLabor =
                    Object.keys(this.vcLabor).length > 0 ? this.sortLaborResultsByLatest(this.vcLabor) : this.vcLabor;
            }

            this.refresh();

            if (!this.selectedFinding || Object.keys(this.selectedFinding).length === 0) {
                this.changeArea({ id: -1, long_text: 'All' }).then(() => {
                    this.selectedFinding = this.selectInitialPatientRecord();
                });
            }

            const selectedFinding = this.vcFindings.find((f) => {
                return f.locator === this.selectedFinding.locator;
            });

            if (!selectedFinding) {
                this.selectedFinding = this.selectInitialPatientRecord();
            }

            this.shouldRenderFormioRenderer = true;
        });
    }

    public onFormChange(data: FormioRendererData[]): void {
        this.formioData = data;
    }

    public base64TextToHTML(base64: string) {
        return atob(base64);
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    /**
     * Toggles the state of the dropdown menu.
     */
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    public onClickOnLabViewerFullScreen($event: LabResult[]): void {
        const data = {
            labResults: $event,
            documentType: 'labResults',
        };
        this.clickOnFullScreen.emit({ data, fullScreen: true });
    }

    /**
     * Emits an event when the fullscreen mode for the image is changed.
     * @param ev - The new fullscreen state.
     */
    public onClickOnFullScreen(ev: { data: any; fullScreen: boolean }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {
                documentPath: this.selectedFinding.documentPath,
                documentType: this.selectedFinding.documentType,
                documentBase64: this.selectedFinding.base64,
            };
        } else if (this.selectedFinding.documentType === 'pdf') {
            ev.data = {
                documentType: this.selectedFinding.documentType,
                documentBase64: this.selectedFinding.base64,
            };
        }
        this.clickOnFullScreen.emit(ev);
    }

    public onClickOnLaborViewer(): void {
        const s = this.findings.find((e) => e.documentType === 'lab-table');
        if (s === this.selectedFinding) {
            this.selectedFinding = this.selectedFindingBeforeLabViewer;
        } else if (s) {
            this.selectedFindingBeforeLabViewer = this.selectedFinding;
            this.selectedFinding = s;
        }
    }

    /**
     * Emits an event when the painting tool state is changed.
     * @param ev - The new painting tool state.
     */
    public onPaintingToolToggleChanged(ev: { data: any; isPaintingToolDialogOpened: boolean }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {
                document_id: this.selectedFinding.id,
                documentType: this.selectedFinding.documentType,
                documentPath: this.selectedFinding.documentPath,
                documentBase64: this.selectedFinding.base64,
                annotations: this.selectedFinding.annotations,
            };
        } else if (this.selectedFinding.documentType === 'pdf') {
            ev.data = {
                document_id: this.selectedFinding.id,
                documentType: this.selectedFinding.documentType,
                documentBase64: this.selectedFinding.base64,
                annotations: this.selectedFinding.annotations,
            };
        }
        this.isPaintingToolOpenedChanged.emit(ev);
    }

    /**
     * Emits an event when the metadata view state is changed.
     * @param ev - The new metadata view state.
     */
    public onMetaDataViewToggleChanged(ev: boolean) {
        this.isMetaDataViewChanged.emit(ev);
    }

    /**
     * Changes the current area and updates the selected patient record.
     * @param area - The object of the new area.
     */
    public async changeArea(area: Area) {
        this.selectedArea = area;
        this.selectedFinding = this.selectInitialPatientRecord();

        if (area.short_text !== 'lab') this.laborViewerChip.deselect();
    }

    /**
     * Selects a patient record by click event.
     * @param event - The event object containing the selected finding.
     */
    public async onClickOnFileListItem(event: any): Promise<void> {
        this.shouldRenderFormioRenderer = false;
        setTimeout(() => {
            this.selectedFinding = event;
            this.shouldRenderFormioRenderer = true;
        });
    }

    public async addNewPatientFindingBy(finding: Finding) {
        if (this.token) {
            /*const data: ApiDocument = {
                documenttext: finding.base64?.split('base64,')[1] ?? '',
                case_id: this.caseId ?? '-1',
                filename: finding.title ? `${finding.title} (Einzelbild)` : '',
                mimetype: 'image',
                formioRendererData: [],
                formioRendererForm: {} as FormioRendererForm,
            };
            await this.docsCache.saveDocument(data);*/

            const i18n: FormioRendererI18n = {
                de: {
                    Eingabe: 'Eingabe',
                    Speichern: 'Speichern',
                    Abbrechen: 'Abbrechen',
                    'Field Set': 'Field Set',
                },
                en: {
                    Eingabe: 'Input',
                    Speichern: 'Save',
                    Abbrechen: 'Cancel',
                    'Field Set': 'Field Set',
                },
            };

            const cid = this.caseDetails?.related_case_id;
            const pid = this.caseDetails?.related_patient_id;

            if (!cid || !pid) {
                console.error('Error creating Doctorformular');
                return;
            }

            const id = uuidv4();
            const data: PatientRecordVCData = {
                finding: {
                    createdBy: '',
                    created_at: '',
                    dateTimeOfRecord: new Date().toISOString(),
                    documentType: 'image',
                    examinationDateTime: new Date(finding.examinationDateTime).toISOString(),
                    id: id,
                    order: 0,
                    title: finding.title ? `${finding.title} (Einzelbild)` : '',
                    validFrom: '',
                    validUntil: '',
                    formioRendererData: [],
                    formioRendererForm: {} as FormioRendererForm,
                    base64: finding.base64?.split('base64,')[1] ?? '',
                },
                record: this.selectedRecord ?? {
                    name: 'Sonstiges',
                    id: '-1',
                },
                area: {
                    long_text: 'Sonstiges',
                    id: -1,
                },
                subarea: {
                    text_long: 'Sonstiges',
                    sort_order: 0,
                    id: -1,
                },
            };

            let vcData: ViewContent<any> = {} as ViewContent<any>;
            vcData = getNewViewContent({
                locator: `document.others.${this.caseId}.${id}`,
                related_patient_id: pid,
                related_case_id: cid,
                data,
                owners: [],
                owner_departments: [],
                main_owner_job_type: 'other',
                created_at: dayjs().toISOString(),
                status: 'final',
                form: {} as FormioRendererForm,
                i18n,
            });

            await this.vcCache.saveViewContent(vcData);
        }
    }

    /**
     * Selects the initial patient record based on the current area.
     */
    public selectInitialPatientRecord(): Finding {
        let finding: Finding = {} as Finding;
        if (!this.selectedRecord && this.records.length > 0) {
            this.selectedRecord = this.records[0];
        }

        if (!this.selectedRecord) return finding;

        finding = this.selectFinding(this.selectedRecord.name, this.selectedArea?.long_text);
        return finding;
    }

    public getFilteredAreasBySelectedRecord(): Area[] {
        return this.areas.filter((area) => {
            // Assuming this.selectedRecord is of type Record
            // and has a 'name' property that corresponds to area.record
            return area?.record === this.selectedRecord?.name;
        });
    }

    public async openTextEditor(): Promise<void> {
        // Opens a dialog for adding a new patient file and prevents it from auto-refocusing
        const dialogRef = this.dialog.open<TextEditorBridgeComponent, any, TextEditorModalResult>(
            TextEditorBridgeComponent,
            {
                restoreFocus: false,
                height: '90%',
                width: '90%',
                data: {
                    caseId: this.caseId,
                },
            }
        );

        const res = await firstValueFrom(dialogRef.afterClosed());

        if (res?.role === 'cancel') return;

        this.newTextDocument.emit(res?.data);
    }

    public async openDoctorFormular() {
        if (this.token?.access_token) {
            const form_vc: ViewContent<any> | undefined | null = await this.vcCache.getViewContentForLocator(
                'form.static.doctors_report_form.1'
            );

            if (!form_vc) {
                this.snackBar.open('Es muss zuerst ein Arztbericht Fromular erstellt und abgespeichtert werden', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
                return;
            }

            form_vc.data.formio_form.components.unshift({
                key: 'title',
                type: 'textfield',
                input: true,
                label: 'Titel des Arztberichts',
                tableView: true,
                labelWidth: 1,
                applyMaskOn: 'change',
                labelMargin: 1,
                labelPosition: 'left-left',
                validateWhenHidden: false,
                'x-data': '',
                'x-show': true,
            });

            const dialogRef = this.dialog.open(FormModalComponent, {
                data: {
                    formioForm: form_vc.data.formio_form,
                    viewContentI18n: form_vc.data.formio_i18n,
                    form_title: 'Neuer Arztbericht',
                },
                panelClass: 'patient-overview-dialog-container',
            });
            const res = await firstValueFrom(dialogRef.afterClosed());

            if (res?.role === 'cancel' || !res?.data) return;

            const cid = this.caseDetails?.related_case_id;
            const pid = this.caseDetails?.related_patient_id;

            if (!cid || !pid) {
                console.error('Error creating Doctorformular');
                return;
            }
            const i18n: FormioRendererI18n = {
                de: {
                    Eingabe: 'Eingabe',
                    Speichern: 'Speichern',
                    Abbrechen: 'Abbrechen',
                    'Field Set': 'Field Set',
                },
                en: {
                    Eingabe: 'Input',
                    Speichern: 'Save',
                    Abbrechen: 'Cancel',
                    'Field Set': 'Field Set',
                },
            };

            let filename = form_vc.data.formio_form.components[0]?.['x-data'];
            //  form_vc.data.formio_form.components.shift();
            //  res.data.shift();

            if (!filename) {
                filename = 'Arztbericht';
            }

            const id = uuidv4();
            const data: PatientRecordVCData = {
                finding: {
                    createdBy: '',
                    created_at: '',
                    dateTimeOfRecord: '',
                    documentType: 'form',
                    examinationDateTime: '',
                    id: id,
                    order: 0,
                    title: filename,
                    validFrom: '',
                    validUntil: '',
                    formioRendererData: res.data,
                    formioRendererForm: form_vc.data.formio_form,
                },
                record: this.selectedRecord ?? {
                    name: 'Sonstiges',
                    id: '-1',
                },
                area: {
                    long_text: 'Sonstiges',
                    id: -1,
                },
                subarea: {
                    text_long: 'Sonstiges',
                    sort_order: 0,
                    id: -1,
                },
            };

            let vcData: ViewContent<any> = {} as ViewContent<any>;
            vcData = getNewViewContent({
                locator: `document.others.${this.caseId}.${id}`,
                related_patient_id: pid,
                related_case_id: cid,
                data,
                owners: [],
                owner_departments: [],
                main_owner_job_type: 'other',
                created_at: dayjs().toISOString(),
                status: 'final',
                form: form_vc.data.formio_form,
                i18n,
            });

            await this.vcCache.saveViewContent(vcData);

            /*const newDoc: ApiDocument = {
                case_id: cid ?? '-1',
                formioRendererData: res.data,
                formioRendererForm: ftf.formio_form,
                filename: filename,
                mimetype: 'form',
            };

            const resp = await this.docsCache.saveDocument(newDoc);

            if (resp.status !== 201) {
                this.snackBar.open('Es konnte kein Arztbericht erstellt werden!', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
            }*/
        }
    }

    public async openNurseFormular() {
        if (this.token?.access_token) {
            const form_vc: ViewContent<any> | undefined | null = await this.vcCache.getViewContentForLocator(
                'form.static.nursing_report_form.1'
            );

            if (!form_vc) {
                this.snackBar.open('Es muss zuerst ein Pflegebericht Fromular erstellt und abgespeichtert werden', '', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['error-snackbar'],
                });
                return;
            }

            form_vc.data.formio_form.components.unshift({
                key: 'title',
                type: 'textfield',
                input: true,
                label: 'Titel des Arztberichts',
                tableView: true,
                labelWidth: 1,
                applyMaskOn: 'change',
                labelMargin: 1,
                labelPosition: 'left-left',
                validateWhenHidden: false,
                'x-data': '',
                'x-show': true,
            });

            const dialogRef = this.dialog.open(FormModalComponent, {
                data: {
                    formioForm: form_vc.data.formio_form,
                    viewContentI18n: form_vc.data.formio_i18n,
                    form_title: 'Neuer Pflegebericht',
                },
                panelClass: 'patient-overview-dialog-container',
            });
            const res = await firstValueFrom(dialogRef.afterClosed());

            if (res?.role === 'cancel') return;

            const cid = this.caseDetails?.related_case_id;
            const pid = this.caseDetails?.related_patient_id;

            if (!cid || !pid) {
                console.error('Error creating Doctorformular');
                return;
            }

            const i18n: FormioRendererI18n = {
                de: {
                    Eingabe: 'Eingabe',
                    Speichern: 'Speichern',
                    Abbrechen: 'Abbrechen',
                    'Field Set': 'Field Set',
                },
                en: {
                    Eingabe: 'Input',
                    Speichern: 'Save',
                    Abbrechen: 'Cancel',
                    'Field Set': 'Field Set',
                },
            };

            let filename = form_vc.data.formio_form.components[0]?.['x-data'];
            form_vc.data.formio_form.components.shift();
            res.data.shift();

            if (!filename) {
                filename = 'Pflegebericht';
            }

            const id = uuidv4();
            const data: any = {
                finding: {
                    createdBy: '',
                    created_at: '',
                    dateTimeOfRecord: '',
                    documentType: 'form',
                    examinationDateTime: '',
                    id: id,
                    order: 0,
                    title: filename,
                    validFrom: '',
                    validUntil: '',
                    formioRendererData: res.data,
                    formioRendererForm: form_vc.data.formio_form,
                },
                record: this.selectedRecord ?? {
                    name: 'Sonstiges',
                    id: '-1',
                },
                area: {
                    long_text: 'Sonstiges',
                    id: -1,
                },
                subarea: {
                    text_long: 'Sonstiges',
                    sort_order: 0,
                    id: -1,
                },
            };

            let vcData: ViewContent<any> = {} as ViewContent<any>;
            vcData = getNewViewContent({
                locator: `document.others.${this.caseId}.${id}`,
                related_patient_id: pid,
                related_case_id: cid,
                data,
                owners: [],
                owner_departments: [],
                main_owner_job_type: 'other',
                created_at: dayjs().toISOString(),
                status: 'final',
                form: form_vc.data.formio_form,
                i18n,
            });

            await this.vcCache.saveViewContent(vcData);

            /*const newDoc: ApiDocument = {
                case_id: cid ?? '-1',
                formioRendererData: res.data,
                formioRendererForm: ftf.formio_form,
                filename: 'Pflegebericht',
                mimetype: 'form',
            };

            const resp = await this.docsCache.saveDocument(newDoc);

            if (resp.status !== 201) {
                this.snackBar.open('Es konnte kein Pflegebericht erstellt werden!', 'Close', {
                    duration: 5000, // Adjust duration as needed
                    panelClass: ['snackbar-danger'],
                });
            }*/
        }
    }

    public async saveForm() {
        const form_data = this.selectedFinding?.formioRendererForm;

        const cid = this.caseDetails?.related_case_id;
        const pid = this.caseDetails?.related_patient_id;

        if (!cid || !pid) {
            console.error('Error creating Doctorformular');
            return;
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        let filename = this.selectedFinding?.title ?? 'Aktenformular';

        const id = this.selectedFinding.locator?.split('.').slice(-1)[0] || uuidv4();

        const data: any = {
            finding: {
                createdBy: '',
                created_at: '',
                dateTimeOfRecord: '',
                documentType: 'form',
                examinationDateTime: '',
                id: id,
                order: 0,
                title: filename,
                validFrom: '',
                validUntil: '',
                formioRendererData: this.formioData,
                formioRendererForm: form_data,
                edit_status: this.selectedFinding?.edit_status || 'completed',
            },
            record: this.selectedRecord ?? {
                name: 'Sonstiges',
                id: '-1',
            },
            area: this.selectedFinding?.area,
            subarea: this.selectedFinding?.subArea,
        };

        let vcData: ViewContent<any> = {} as ViewContent<any>;
        vcData = getNewViewContent({
            locator: this.selectedFinding.locator ?? `document.others.${this.caseId}.${id}`,
            related_patient_id: pid,
            related_case_id: cid,
            data,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            status: 'final',
            form: form_data,
            i18n,
        });

        await this.vcCache.saveViewContent(vcData);

        this.selectedFinding = { ...vcData.data.finding, locator: vcData.locator };
    }

    public async deleteFinding(record: Finding) {
        // document.others.10.929
        if (confirm('Möchten Sie diesen Befund wirklich löschen?')) {
            let vc = this.vcFindings.find((f: ViewContent<PatientRecordVCData>) => {
                return f.locator === record.locator;
            });
            if (vc) {
                this.deletedFinding.emit(vc);
            }
        }
    }

    private selectFinding(recordName: string, areaText?: string): Finding {
        let finding: Finding = {} as Finding;

        if (areaText && areaText !== 'All') {
            const areas = this.findingsByRecords[recordName];
            if (areas) {
                const subAreas = areas[areaText];
                if (subAreas) {
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        finding = subAreas[firstSubArea][0] || ({} as Finding);
                        return finding;
                    }
                }
            }
        } else {
            const allRecords = this.findingsByRecords[recordName];
            if (allRecords) {
                const firstArea = Object.keys(allRecords)[0];
                if (firstArea) {
                    const subAreas = allRecords[firstArea];
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        finding = subAreas[firstSubArea][0] || ({} as Finding);
                        return finding;
                    }
                }
            }
        }

        return finding;
    }

    private refresh() {
        this.findingsByRecords = {};
        this.findingsByRecords = this.convertToFindingsByRecords(
            this.vcLabor && Object.keys(this.vcLabor).length > 0
                ? [...this.vcFindings, this.vcLabor]
                : [...this.vcFindings]
        );
    }

    private sortFindingsByLatest(findings: ViewContent<any>[]): ViewContent<any>[] {
        return findings.sort((a, b) => {
            const dateA = new Date(a.created_at).getTime();
            const dateB = new Date(b.created_at).getTime();
            return dateB - dateA; // Absteigend sortieren (neuste zuerst)
        });
    }

    private sortLaborResultsByLatest(labResults: ViewContent<LabReport>): ViewContent<LabReport> {
        labResults.data.results.sort((a, b) => {
            const dateA = new Date(a.reported_date_time).getTime();
            const dateB = new Date(b.reported_date_time).getTime();
            return dateB - dateA;
        });

        return labResults;
    }

    private convertToFindingsByRecords(dataArray: ViewContent<any>[]): FindingsByRecords<Finding> {
        const findingsByRecords: FindingsByRecords<Finding> = {};

        dataArray.forEach((vc: ViewContent<any> | any) => {
            const isDocumentType = vc.locator.startsWith('document.');
            const isLaborResultType = vc.locator.startsWith('labor.results');

            if (isDocumentType && vc.status !== 'archived') {
                const getNormalizedName = (obj: any, longKey: string, shortKey: string) =>
                    (obj?.[longKey] || obj?.[shortKey] || 'Sonstiges') === 'All'
                        ? 'Sonstiges'
                        : obj?.[longKey] || obj?.[shortKey] || 'Sonstiges';

                const recordName = getNormalizedName(vc.data?.record, 'name', '');
                const areaName = getNormalizedName(vc.data?.area, 'long_text', 'short_text');
                const subAreaName = getNormalizedName(vc.data?.subarea, 'text_long', 'text_short');

                const findingResult = this.vcFindings.filter((vcItem: any) => {
                    const itemRecordName = getNormalizedName(vcItem.data?.record, 'name', '');
                    const itemAreaName = getNormalizedName(vcItem.data?.area, 'long_text', 'short_text');
                    const itemSubAreaName = getNormalizedName(vcItem.data?.subarea, 'text_long', 'text_short');

                    return (
                        itemRecordName === recordName && itemAreaName === areaName && itemSubAreaName === subAreaName
                    );
                });

                findingResult.forEach((t: any) => {
                    const finding: Finding = {
                        id: t?.data?.finding?.id?.toString() ?? '-1',
                        createdBy: t.data?.finding?.createdBy ?? '',
                        created_at: t.data?.finding?.created_at ?? '',
                        title: t.data?.finding?.filename ?? t.data?.finding?.title ?? '',
                        order: 0,
                        area: t.data?.area ?? {},
                        subArea: t.data?.subarea ?? {},
                        examinationDateTime: t.data?.finding?.created_at ?? '',
                        dateTimeOfRecord: t.data?.finding?.dateTimeOfRecord ?? t.data?.finding?.created_at ?? '',
                        validFrom: t.data?.record?.validFrom ?? '',
                        validUntil: t.data?.record?.validUntil ?? '',
                        documentType: t.data?.finding?.documentType ?? detectDocumentType(t.data?.finding?.base64),
                        base64: t.data?.finding?.base64 ?? '',
                        formioRendererData: t.data?.finding?.formioRendererData ?? '',
                        formioRendererForm: t.data?.finding?.formioRendererForm ?? '',
                        locator: t.locator,
                        status: t.status,
                        annotations: t.data?.annotations,
                        edit_status: t.data?.finding?.edit_status,
                    };

                    findingsByRecords[recordName] ??= {};
                    findingsByRecords[recordName][areaName] ??= {};
                    findingsByRecords[recordName][areaName][subAreaName] ??= [];

                    const existingFindings = findingsByRecords[recordName][areaName][subAreaName];
                    if (!existingFindings.some((existingFinding) => existingFinding.id === finding.id)) {
                        existingFindings.push(finding);
                    }
                });
            }

            if (isLaborResultType) {
                const labTable: Finding = {
                    id: vc.id.toString(),
                    createdBy: vc.created_by,
                    created_at: vc.created_at,
                    title: `Labortabelle`,
                    order: 0,
                    examinationDateTime: '',
                    dateTimeOfRecord: '',
                    validFrom: '',
                    validUntil: '',
                    documentType: 'lab-table',
                    labResult: vc.data.results,
                };

                this.findings.push(labTable);

                vc.data.results.forEach((result: LabResult | any) => {
                    const record = result?.record ?? ({} as Record);
                    const recordName = record?.name || 'Sonstiges';

                    const area = result?.area ?? ({} as Area);
                    let areaName = area?.long_text || area?.short_text || 'Sonstiges';
                    if (areaName === 'All') {
                        areaName = 'Sonstiges';
                    }

                    const subArea = result?.subarea ?? ({} as SubArea);
                    const subAreaName = subArea?.text_long || subArea?.text_short || 'Sonstiges';

                    const labReport: Finding = {
                        id: result.id.toString() ?? '-1',
                        createdBy: result.modified_by ?? '',
                        created_at: result.modified_at ?? '',
                        title: `Laborbefund ${result.id.toString()}`,
                        order: 0,
                        examinationDateTime: result.received_date_time ?? '',
                        dateTimeOfRecord: result.reported_date_time ?? '',
                        validFrom: '',
                        validUntil: '',
                        documentType: result?.documentType ?? '',
                        labResult: result,
                    };

                    findingsByRecords[recordName] ??= {};
                    findingsByRecords[recordName][areaName] ??= {};
                    findingsByRecords[recordName][areaName][subAreaName] ??= [];
                    findingsByRecords[recordName][areaName][subAreaName].push(labReport);
                });
            }
        });

        const records = Object.keys(findingsByRecords);
        records.forEach((r) => {
            const areas = Object.keys(findingsByRecords[r]);
            findingsByRecords[r]['All'] ??= {};
            areas.forEach((a) => {
                const subAreas = Object.keys(findingsByRecords[r][a]);
                subAreas.forEach((s) => {
                    findingsByRecords[r]['All'][s] ??= [];
                    findingsByRecords[r]['All'][s].push(...findingsByRecords[r][a][s]);
                });
            });
        });

        function detectDocumentType(base64String: string): string {
            const cleanBase64 = base64String.trim().toLowerCase();
            if (cleanBase64.startsWith('jvberi0')) return 'pdf';
            if (
                cleanBase64.startsWith('/9j/') ||
                cleanBase64.startsWith('ivborw0kggo') ||
                cleanBase64.startsWith('r0lgodlh')
            )
                return 'image';
            if (cleanBase64.startsWith('aaaa') && cleanBase64.includes('redtq==')) return 'dicom';
            if (
                cleanBase64.startsWith('uf8=') ||
                cleanBase64.startsWith('77u/') ||
                cleanBase64.startsWith('0m8r4k') ||
                cleanBase64.startsWith('XEVc')
            )
                return 'text';
            if (cleanBase64.startsWith('pk') || cleanBase64.startsWith('ums=')) return 'archive';
            if (cleanBase64.startsWith('suqzbaaaaaa')) return 'audio';
            if (cleanBase64.startsWith('id3')) return 'audio';
            if (cleanBase64.startsWith('riffffffff')) return 'video';
            if (cleanBase64.startsWith('0000002066747970') || cleanBase64.startsWith('aaaahg==')) return 'video';
            if (cleanBase64.startsWith('dw9yza==') || cleanBase64.startsWith('0m8r4k')) return 'document';
            if (cleanBase64.startsWith('ums+') || cleanBase64.startsWith('0m8r4k')) return 'spreadsheet';
            if (cleanBase64.startsWith('dgi6')) return 'presentation';

            try {
                if (/^[\x00-\x7F]*$/.test(atob(base64String))) return 'base64_text';
            } catch {}
            return 'base64_text';
        }

        return findingsByRecords;
    }
}
