import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PatientListItem } from '../../../models/patient.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Cp2MatPaginatorIntl } from '../../../shared/Cp2MatPaginatorIntl';
import { MatChipSelectionChange, MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { OrgaTabsMedicalOrderComponent } from '../orga-tabs-medical-order/orga-tabs-medical-order.component';
import { OrgaTabsTasksComponent } from '../orga-tabs-tasks/orga-tabs-tasks.component';
import { OrgaTabsCommentsComponent } from '../orga-tabs-comments/orga-tabs-comments.component';
import { OrgaTabsDiagFindingsComponent } from '../orga-tabs-diag-findings/orga-tabs-diag-findings.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import { Patient_Details } from '../../../models/view-content.models/view-content.model';

@Component({
    selector: 'app-workflow-tabs',
    templateUrl: './workflow-tabs.component.html',
    styleUrls: ['./workflow-tabs.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        CommonModule,
        MatChipsModule,
        OrgaTabsMedicalOrderComponent,
        OrgaTabsTasksComponent,
        OrgaTabsCommentsComponent,
        OrgaTabsDiagFindingsComponent,
        CommonModule,
        MatButtonToggleModule,
        FormsModule,
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: Cp2MatPaginatorIntl }],
})
export class WorkflowTabsComponent implements OnInit, OnChanges {
    @ViewChild(MatSort) sort: MatSort | null = null;
    @ViewChild(MatTable) table: MatTable<any> | undefined;

    /** A string value used to filter the table data. This value is bound to a text input for dynamic filtering. */
    @Input() filterValue: string = '';

    /**
     * An array of `PatientListItem` objects to be displayed in the table. This data is passed into the component
     * and used to populate the rows of the table. Since the workflow page and orga share the same data structure
     * so the interface PatientListItem is here
     */
    @Input() workflowDataPatients: PatientListItem[] = [];
    @Input() workflowDataPatientsDetails: Patient_Details[] = [];

    /** for the material chips to switch between 4 chips */
    @Input() selectedToggle: string | null | undefined = 'tasks';
    @Input() viewContentI18n: FormioRendererI18n | undefined;

    @Output() selectedIcon: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectionChange: EventEmitter<MatChipSelectionChange> | undefined;

    public constructor(private router: Router) {}

    get isWorkflowRoute(): boolean {
        const currentUrl = this.router.url;
        return currentUrl.endsWith('/workflow');
    }

    public ngOnInit(): void {
        // this.dataSource.data = this.workflowDataPatients as PatientListItem[];
        // this.totalRecords = this.dataSource.data.length;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['workflowDataPatients'] || changes['filterValue']) {
            this.applyFilter();
        }
    }

    public onChipSelectChange(event: MatChipSelectionChange) {
        this.selectionChange?.emit(event);
    }

    private applyFilter(): void {
        const filteredPatients = this.workflowDataPatients?.filter((item) =>
            Object.values(item).some((val) => val.toString().toLowerCase().includes(this.filterValue))
        );
        // this.dataSource.data = filteredPatients as PatientListItem[];
    }
}
